import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Card, Table } from "react-bootstrap";
import * as externalApiApi from "apis/ExternalApiApi";
import "./AddExternalApiParameterComponent.scss";
import { getDate, getTime } from "utils/date";
import { apiWrapper } from "apis/CommonApi";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const HeaderNameOptions = {
  RTU_IMEI: "0",
  D_TIME: "1",
  MODEL_ID: "2",
  BUSINESS_ID: "3",
  SERVICE_ID: "4",
  MULTI_ID: "5",
};

const getHeaderNameOptionName = (value) => {
  let name = "";
  const key = Object.keys(HeaderNameOptions)[value];
  switch (key) {
    case "RTU_IMEI":
      name = "RTU-IMEI";
      break;
    case "D_TIME":
      name = "D-Time";
      break;
    case "MODEL_ID":
      name = "ModelID";
      break;
    case "BUSINESS_ID":
      name = "BusinessID";
      break;
    case "SERVICE_ID":
      name = "ServiceID";
      break;
    case "MULTI_ID":
      name = "MultiID";
      break;
    default:
      break;
  }
  return name;
};

const getHeaderNameOptionValue = (name) => {
  let value = "-1";
  switch (name) {
    case "RTU-IMEI":
      value = "0";
      break;
    case "D-Time":
      value = "1";
      break;
    case "ModelID":
      value = "2";
      break;
    case "BusinessID":
      value = "3";
      break;
    case "ServiceID":
      value = "4";
      break;
    case "MultiID":
      value = "5";
      break;
    default:
      break;
  }
  return value;
};

export default function AddExternalApiParameterComponent(props) {
  const maxHeaderCount = 10;
  const maxBodyCount = 10;
  const defaultHeaderCount = 2;
  const defaultBodyCount = 1;
  const disableText = "자동 입력";
  const [formData, setFormData] = useState(null);
  const [validated, setValidated] = useState(false);
  const isEdit = props.item !== null;

  useEffect(() => {
    setFormDataProperty("id", "");
    setFormDataProperty("companyName", "");
    setFormDataProperty("writer", "");
    setFormDataProperty("writeDate", getDate(new Date()));
    setFormDataProperty("serverUrl", "");

    let header = [];
    for (let i = 0; i < maxHeaderCount; i++) {
      header = [
        ...header,
        {
          headerName: null,
          headerValue: "",
          required: false,
        },
      ];
    }
    header[0] = {
      ...header[0],
      headerName: {
        value: HeaderNameOptions.RTU_IMEI,
        label: getHeaderNameOptionName(HeaderNameOptions.RTU_IMEI),
      },
      headerValue: disableText,
      required: true,
    };
    header[1] = {
      ...header[1],
      headerName: {
        value: HeaderNameOptions.D_TIME,
        label: getHeaderNameOptionName(HeaderNameOptions.D_TIME),
      },
      headerValue: disableText,
      required: true,
    };
    setFormDataProperty("header", header);

    let body = [];
    for (let i = 0; i < maxBodyCount; i++) {
      body = [...body, { bodyName: "", bodyValue: "", required: false }];
    }
    body[0] = {
      ...body[0],
      bodyName: "Data",
      bodyValue: "표준 데이터 수신 DB 데이터 변환",
      required: true,
    };
    setFormDataProperty("body", body);

    let items;
    if (isEdit) {
      const id = props.item.id;

      apiWrapper(async () => {
        items = await externalApiApi.getExternalApiParameter(id);

        const { status, data } = items;
        if (status === true) {
          setFormDataProperty("id", data.id);
          setFormDataProperty("companyName", data.companyName);
          setFormDataProperty("writer", data.writer);
          setFormDataProperty("writeDate", getDate(new Date(data.writeDate)));
          setFormDataProperty("serverUrl", data.serverUrl);

          //header
          if (data.header) {
            let newHeader = header;
            Object.entries(data.header).map(
              (item, index) =>
                (newHeader[index + defaultHeaderCount] = {
                  headerName: {
                    value:
                      getHeaderNameOptionValue(item[0]) > -1
                        ? getHeaderNameOptionValue(item[0])
                        : item[0],
                    label: item[0],
                  },
                  headerValue: item[1],
                  required: item[0] === "RTU-IMEI" || item[0] === "D-Time",
                })
            );
            setFormDataProperty("header", newHeader);
          }

          //body
          if (data.body) {
            let newBody = body;
            Object.entries(data.body).map(
              (item, index) =>
                (newBody[index + defaultBodyCount] = {
                  bodyName: item[0],
                  bodyValue: item[1],
                  required: item[0] === "Data",
                })
            );
            setFormDataProperty("body", newBody);
          }
        }
      });
    }
  }, []);

  const setFormDataProperty = (name, value) => {
    setFormData((currentFormData) => {
      const nextFormData = {
        ...currentFormData,
        [name]: value,
      };
      //console.log(nextFormData);
      return nextFormData;
    });
  };

  const onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFormDataProperty(name, value);
  };

  const isUserInputHeaderName = (headerName) => {
    return Object.values(HeaderNameOptions).includes(headerName) === false;
  };

  const checkValid = () => {
    //Header 검사
    for (let i = 0; i < formData.header.length; i++) {
      const header = formData.header[i];
      if (header.headerName === null && header.headerValue.trim() !== "") {
        alert(`[Header] ${i + 1}행 Header Name 값을 입력하세요.`);
        return false;
      } else if (
        header.headerName !== null &&
        isUserInputHeaderName(header.headerName.value) &&
        header.headerValue.trim() === ""
      ) {
        alert(`[Header] ${i + 1}행 Header Value 값을 입력하세요.`);
        return false;
      } else if (
        header.headerName !== null &&
        formData.header.some(
          (a, index) =>
            i !== index &&
            a.headerName !== null &&
            a.headerName.label.toUpperCase() ===
              header.headerName.label.toUpperCase()
        )
      ) {
        alert(
          `[Header] Header Name이 중복됩니다. (${header.headerName.label})`
        );
        return false;
      }
    }

    //Body 검사
    for (let i = 0; i < formData.body.length; i++) {
      const body = formData.body[i];
      if (body.bodyName.trim() === "" && body.bodyValue.trim() !== "") {
        alert(`[Body] ${i + 1}행 Body Name 값을 입력하세요.`);
        return false;
      } else if (body.bodyName.trim() !== "" && body.bodyValue.trim() === "") {
        alert(`[Body] ${i + 1}행 Body Value 값을 입력하세요.`);
        return false;
      } else if (
        body.bodyName.trim() !== "" &&
        formData.body.some(
          (a, index) =>
            i !== index &&
            a.bodyName.trim().toUpperCase() ===
              body.bodyName.trim().toUpperCase()
        )
      ) {
        alert(`[Body] Body Name이 중복됩니다. (${body.bodyName})`);
        return false;
      }
    }

    return true;
  };

  const handleSave = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else if (checkValid() === false) {
      e.stopPropagation();
    } else {
      let header = {};
      formData.header.map(
        (item, index) =>
          index >= defaultHeaderCount &&
          item.headerName !== null &&
          (header = {
            ...header,
            [item.headerName.label]: item.headerValue,
          })
      );
      let body = {};
      formData.body.map(
        (item, index) =>
          index >= defaultBodyCount &&
          item.bodyName.trim() &&
          item.bodyValue.trim() &&
          (body = { ...body, [item.bodyName]: item.bodyValue })
      );

      let requestBody = {
        companyName: formData.companyName,
        writer: formData.writer,
        writeDate: formData.writeDate + " " + getTime(new Date()),
        serverUrl: formData.serverUrl,
        header: header,
        body: body,
      };

      //console.log("requestBody", JSON.stringify(requestBody));

      apiWrapper(async () => {
        let result;
        if (isEdit) {
          const id = props.item.id;
          result = await externalApiApi.updateExternalApiParameter(
            id,
            requestBody
          );
        } else {
          result = await externalApiApi.createExternalApiParameter(requestBody);
        }

        const { status, data } = result;
        if (status === true) {
          alert("저장 완료하였습니다.");
        } else {
          alert(`저장 실패하였습니다.\n${data}`);
        }
      });
    }

    //유효성 검사 완료 처리
    setValidated(true);
  };

  const handleChange = (e, index, propertyName, propertyValue) => {
    const name = e.target.name;
    const value = e.target.value;

    let newValue = propertyValue.map((changedItem, changedIndex) =>
      changedIndex === index ? { ...changedItem, [name]: value } : changedItem
    );

    setFormDataProperty(propertyName, newValue);
  };

  const handleChangeHeaderName = (e, index, propertyValue) => {
    var headerName = "headerName";

    //Header Value 값 초기화
    propertyValue[index].headerValue = "";

    //Header Name 값 설정
    let newValue = propertyValue.map((changedItem, changedIndex) =>
      changedIndex === index
        ? {
            ...changedItem,
            [headerName]:
              e === null ? null : { value: e.value, label: e.label },
          }
        : changedItem
    );
    setFormDataProperty("header", newValue);
  };

  const getHeaderNames = () => {
    let list = [];

    Object.values(HeaderNameOptions).map((item) => {
      if (
        item !== HeaderNameOptions.RTU_IMEI &&
        item !== HeaderNameOptions.D_TIME
      ) {
        list.push({ value: item, label: getHeaderNameOptionName(item) });
      }
    });

    return list;
  };

  return (
    <>
      {formData && (
        <Modal
          {...props}
          show
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="add-external-api-parameter modal-80w"
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <Form noValidate onSubmit={handleSave}>
              <Row className="mb-3">
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>
                      관리코드
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="id"
                        value={formData.id}
                        onChange={onChange}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                      API 업체명
                    </Form.Label>
                    <Col>
                      <Form.Control
                        isInvalid={validated && !formData.companyName}
                        type="text"
                        placeholder=""
                        name="companyName"
                        value={formData.companyName}
                        onChange={onChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        API 업체명을 입력하세요.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>
                      작성자
                    </Form.Label>
                    <Col>
                      <Form.Control
                        isInvalid={validated && !formData.writer}
                        type="text"
                        placeholder=""
                        name="writer"
                        value={formData.writer}
                        onChange={onChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        작성자를 입력하세요.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>
                      작성일자
                    </Form.Label>
                    <Col>
                      <Form.Control
                        isInvalid={validated && !formData.writeDate}
                        type="date"
                        placeholder=""
                        name="writeDate"
                        value={formData.writeDate}
                        onChange={onChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        작성일자를 선택하세요.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Label column sm={3}>
                데이터 전송 서버
              </Form.Label>
              <Card className="mb-3">
                <Card.Body>
                  <Row className="align-items-center">
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          데이터 POST
                        </Form.Label>
                        <Form.Label column sm={3}>
                          Request URL
                        </Form.Label>
                        <Col>
                          <Form.Control
                            isInvalid={validated && !formData.serverUrl}
                            type="text"
                            placeholder=""
                            name="serverUrl"
                            value={formData.serverUrl}
                            onChange={onChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Request URL을 입력하세요.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col></Col>
                  </Row>
                </Card.Body>
              </Card>
              <Row>
                <Col>
                  <Form.Label column sm={3}>
                    Header
                  </Form.Label>
                  <Card className="table-container mb-3">
                    <Card.Body>
                      <div className="table-table">
                        <Table bordered hover style={{ textAlign: "center" }}>
                          <thead className="table-header">
                            <tr>
                              <th>No</th>
                              <th>Header Name</th>
                              <th>Header Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData.header &&
                              formData.header.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <CreatableSelect
                                      options={getHeaderNames()}
                                      name="headerName"
                                      placeholder=""
                                      value={item.headerName}
                                      onChange={(e) =>
                                        handleChangeHeaderName(
                                          e,
                                          index,
                                          formData.header
                                        )
                                      }
                                      formatCreateLabel={(inputValue) =>
                                        `${inputValue}`
                                      }
                                      isDisabled={item.required}
                                      isClearable
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      isInvalid={
                                        validated &&
                                        item.required &&
                                        !item.headerValue
                                      }
                                      type="text"
                                      name="headerValue"
                                      value={
                                        item.headerName !== null &&
                                        isUserInputHeaderName(
                                          item.headerName.value
                                        ) === false
                                          ? disableText
                                          : item.headerValue
                                      }
                                      disabled={
                                        item.required ||
                                        (item.headerName !== null &&
                                          isUserInputHeaderName(
                                            item.headerName.value
                                          ) === false)
                                      }
                                      required={item.required}
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          index,
                                          "header",
                                          formData.header
                                        )
                                      }
                                      style={{ textAlign: "center" }}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Form.Label column sm={3}>
                    Body
                  </Form.Label>
                  <Card className="table-container mb-3">
                    <Card.Body>
                      <div className="table-table">
                        <Table bordered hover style={{ textAlign: "center" }}>
                          <thead className="table-header">
                            <tr>
                              <th>No</th>
                              <th>Body Name</th>
                              <th>Body Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData.body &&
                              formData.body.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      name="bodyName"
                                      value={item.bodyName}
                                      disabled={item.required}
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          index,
                                          "body",
                                          formData.body
                                        )
                                      }
                                      style={{ textAlign: "center" }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      isInvalid={
                                        validated &&
                                        item.required &&
                                        !item.bodyValue
                                      }
                                      type="text"
                                      name="bodyValue"
                                      value={item.bodyValue}
                                      disabled={item.required}
                                      required={item.required}
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          index,
                                          "body",
                                          formData.body
                                        )
                                      }
                                      style={{ textAlign: "center" }}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <span className="d-flex">
                <Button type="submit" className="ms-auto" variant="primary">
                  저장
                </Button>
              </span>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
