import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import LoginRequiredRoute from "./utils/LoginRequiredRoute";
import Main from "./Main";

function App() {
  return (
    <>
      <Routes>
        <Route path="/login/*" element={<LoginPage />} />

        <Route
          path="*"
          element={
            <LoginRequiredRoute>
              <Main />
            </LoginRequiredRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
