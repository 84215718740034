import { apiWrapper } from "apis/CommonApi";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  EnergyTypeChoice,
  getEnergyTypeName,
  getRtuAckTypeName,
  getRtuOperationModeName,
  getUartBaudRateName,
  getUartInterfaceName,
  getUseName,
  RtuAckTypeChoice,
  RtuOperationModeChoice,
  UartBaudRateChoice,
  UartInterfaceChoice,
  UseChoice,
} from "utils/common";
import "./RtuSettingDetailPage.scss";
import * as rtuSettingApi from "../apis/RtuSettingApi";
import Select from "react-select";
import { convertHexToNumber, convertNumberToHex } from "utils/number";

export default function RtuSettingDetailPage(props) {
  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [rtuImeis, setRtuImeis] = useState([]);
  const [formData, setFormData] = useState(null);
  const [selectedRtuImei, setSelectedRtuImei] = useState(null);

  useEffect(() => {
    setFormDataProperty("businessId", null);
    setFormDataProperty("serviceId", null);
    setFormDataProperty("opMode", "");
    setFormDataProperty("reserved", 0);
    setFormDataProperty("ackType", "0");
    setFormDataProperty("ackValue1", null);
    setFormDataProperty("ackValue2", null);
    setFormDataProperty("energyType", null);
    setFormDataProperty("multiCount", null);
    setFormDataProperty("multi1Address", null);
    setFormDataProperty("multi2Address", null);
    setFormDataProperty("multi3Address", null);
    setFormDataProperty("multi4Address", null);
    setFormDataProperty("uartInterface", "");
    setFormDataProperty("uartBaudrate", "");
    setFormDataProperty("bootMsg", null);
    setFormDataProperty("uploadMsg", null);
    setFormDataProperty("noResponseMsg", null);
    setFormDataProperty("period", null);
    setFormDataProperty("interval", null);
    setFormDataProperty("waitTime", null);
    setFormDataProperty("queryCount", null);
    setFormDataProperty("queryIndex", null);
    setFormDataProperty("rtuQuery", null);
    setFormDataProperty("serverUrl", "61.83.136.15");
    setFormDataProperty("serverPort", "20001");
    setFormDataProperty("fwDlUrl", "alliothub.com:30001");
    setFormDataProperty("fwDlFileName", null);
    setFormDataProperty("fwDlId", "root");
    setFormDataProperty("fwDlPw", "Server^!))22");

    apiWrapper(async () => {
      if (id) {
        const result = await rtuSettingApi.getRtuSetting(id);
        const { status, data } = result;
        if (status === true) {
          setSelectedRtuImei({ value: data.rtuId, label: data.rtuImei });
          setFormDataProperty(
            "businessId",
            data.businessId !== null
              ? convertNumberToHex(data.businessId)
              : data.businessId
          );
          setFormDataProperty(
            "serviceId",
            data.serviceId !== null
              ? convertNumberToHex(data.serviceId)
              : data.serviceId
          );
          setFormDataProperty("opMode", data.opMode);
          setFormDataProperty("reserved", data.reserved);
          setFormDataProperty("ackType", data.ackType);
          setFormDataProperty("ackValue1", data.ackValue1);
          setFormDataProperty("ackValue2", data.ackValue2);
          setFormDataProperty("energyType", data.energyType);
          setFormDataProperty("multiCount", data.multiCount);
          setFormDataProperty("multi1Address", data.multi1Address);
          setFormDataProperty("multi2Address", data.multi2Address);
          setFormDataProperty("multi3Address", data.multi3Address);
          setFormDataProperty("multi4Address", data.multi4Address);
          setFormDataProperty("uartInterface", data.uartInterface);
          setFormDataProperty("uartBaudrate", data.uartBaudrate);
          setFormDataProperty("bootMsg", data.bootMsg);
          setFormDataProperty("uploadMsg", data.uploadMsg);
          setFormDataProperty("noResponseMsg", data.noResponseMsg);
          setFormDataProperty("period", data.period);
          setFormDataProperty("interval", data.interval);
          setFormDataProperty("waitTime", data.waitTime);
          setFormDataProperty("queryCount", data.queryCount);
          setFormDataProperty("queryIndex", data.queryIndex);
          setFormDataProperty("rtuQuery", data.rtuQuery);
          setFormDataProperty("serverUrl", data.serverUrl);
          setFormDataProperty("serverPort", data.serverPort);
          setFormDataProperty("fwDlUrl", data.fwDlUrl);
          setFormDataProperty("fwDlFileName", data.fwDlFileName);
          setFormDataProperty("fwDlId", data.fwDlId);
          setFormDataProperty("fwDlPw", data.fwDlPw);
        }
      } else {
        //RTU IMEI 설정
        let rtuImeis = await rtuSettingApi.getSettingRtuImeis();
        const { status, data } = rtuImeis;
        if (status === true) {
          setRtuImeis(data);
        }
      }
    });

    const state = {};
    const title = "";
    let url = "/rtu-setting/detail/";
    if (id) {
      url = `/rtu-setting/detail/${id}`;
    }
    window.history.pushState(state, title, url);
    window.onpopstate = handleBack;
    return () => {
      window.onpopstate = null;
    };
  }, []);

  const handleBack = () => {
    navigate(location.state?.prevUrl, {
      state: { condition: location.state?.condition },
    });
  };

  const setFormDataProperty = (name, value) => {
    setFormData((currentFormData) => {
      const nextFormData = {
        ...currentFormData,
        [name]: value,
      };
      //console.log(nextFormData);
      return nextFormData;
    });
  };

  const onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setFormDataProperty(name, value);
  };

  const handleChangeRtuImei = (e) => {
    setSelectedRtuImei(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const submitterId = e.nativeEvent.submitter.id;
    if (submitterId === "save") {
      //저장
      save(false);
    } else if (submitterId === "saveSend") {
      //저장 후 전송
      save(true);
    }
  };

  const save = (isSend) => {
    let requestBody = {
      businessId:
        formData.businessId !== null
          ? convertHexToNumber(formData.businessId)
          : null,
      serviceId:
        formData.serviceId !== null
          ? convertHexToNumber(formData.serviceId)
          : null,
      opMode: formData.opMode,
      reserved: formData.reserved,
      ackType: formData.ackType,
      ackValue1:
        formData.ackValue1 !== null ? parseInt(formData.ackValue1) : null,
      ackValue2:
        formData.ackValue2 !== null ? parseInt(formData.ackValue2) : null,
      energyType: formData.energyType !== null ? formData.energyType : null,
      multiCount:
        formData.multiCount !== null ? parseInt(formData.multiCount) : null,
      multi1Address:
        formData.multi1Address !== null
          ? parseInt(formData.multi1Address)
          : null,
      multi2Address:
        formData.multi2Address !== null
          ? parseInt(formData.multi2Address)
          : null,
      multi3Address:
        formData.multi3Address !== null
          ? parseInt(formData.multi3Address)
          : null,
      multi4Address:
        formData.multi4Address !== null
          ? parseInt(formData.multi4Address)
          : null,
      uartInterface: formData.uartInterface,
      uartBaudrate: formData.uartBaudrate,
      bootMsg: formData.bootMsg !== null ? formData.bootMsg : null,
      uploadMsg: formData.uploadMsg !== null ? formData.uploadMsg : null,
      noResponseMsg:
        formData.noResponseMsg !== null ? formData.noResponseMsg : null,
      period: formData.period !== null ? parseInt(formData.period) : null,
      interval: formData.interval !== null ? parseInt(formData.interval) : null,
      waitTime: formData.waitTime !== null ? parseInt(formData.waitTime) : null,
      queryCount:
        formData.queryCount !== null ? parseInt(formData.queryCount) : null,
      queryIndex: formData.queryIndex !== null ? formData.queryIndex : null,
      rtuQuery: formData.rtuQuery !== null ? formData.rtuQuery : null,
      serverUrl: formData.serverUrl !== null ? formData.serverUrl : null,
      serverPort:
        formData.serverPort !== null ? parseInt(formData.serverPort) : null,
      fwDlUrl: formData.fwDlUrl !== null ? formData.fwDlUrl : null,
      fwDlFileName:
        formData.fwDlFileName !== null ? formData.fwDlFileName : null,
      fwDlId: formData.fwDlId !== null ? formData.fwDlId : null,
      fwDlPw: formData.fwDlPw !== null ? formData.fwDlPw : null,
      rtuId: parseInt(selectedRtuImei.value),
      isSaveAfterSend: isSend,
    };

    apiWrapper(async () => {
      let result;
      if (id) {
        requestBody = { ...requestBody, id: parseInt(id) };
        result = await rtuSettingApi.updateRtuSetting(id, requestBody);
      } else {
        result = await rtuSettingApi.createRtuSetting(requestBody);
      }

      const { status, data } = result;
      if (status === true) {
        alert("저장 완료하였습니다.");
        handleBack();
      } else {
        alert(`저장 실패하였습니다.\n${data}`);
      }
    });
  };

  const getRtuImeis = () => {
    let list = [];

    if (rtuImeis) {
      rtuImeis.map((item) => {
        list.push({ value: item.id, label: item.rtuImei });
      });
    }

    return list;
  };

  const handleDelete = (e) => {
    if (window.confirm("삭제하시겠습니까?")) {
      apiWrapper(async () => {
        const result = await rtuSettingApi.deleteRtuSetting(id);

        const { status, data } = result;
        if (status === true) {
          alert("삭제되었습니다.");
          handleBack();
        } else {
          alert(`삭제 실패하였습니다.\n${data}`);
        }
      });
    }
  };

  return (
    <>
      <Container fluid className="mb-3 d-flex align-items-center">
        <h1>RTU 설정 정보</h1>
        <span className="ms-auto">
          <Button
            type="submit"
            form="form"
            className="ms-2"
            variant="primary"
            id="save"
          >
            저장
          </Button>
          <Button
            type="submit"
            form="form"
            className="ms-2"
            variant="primary"
            id="saveSend"
          >
            저장 후 전송
          </Button>
          {id && (
            <Button
              form="form"
              className="ms-2"
              variant="primary"
              onClick={handleDelete}
            >
              삭제
            </Button>
          )}
        </span>
      </Container>

      {formData && (
        <div className="rtu-set-detail">
          <div className="contents-wrap">
            <Form id="form" onSubmit={handleSubmit}>
              <div className="flex-wrap">
                <div className="left-wrap">
                  <div className="group-box">
                    <h2>RTU IMEI</h2>
                    <Form.Group as={Row}>
                      <Form.Label column>RTU Registration(*)</Form.Label>
                      <Col>
                        <Select
                          options={getRtuImeis()}
                          name="rtuId"
                          placeholder=""
                          value={selectedRtuImei}
                          onChange={handleChangeRtuImei}
                          isDisabled={id}
                          required
                        />
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="group-box">
                    <h2>Header Set</h2>
                    <Form.Group as={Row}>
                      <Form.Label column>사업자 ID(*)</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="businessId"
                          value={
                            formData.businessId !== null
                              ? formData.businessId
                              : ""
                          }
                          onChange={onChange}
                          required
                        />
                        <div className="help">00-00 ~ FF-FF</div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>서비스 ID(*)</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="serviceId"
                          value={formData.serviceId || ""}
                          onChange={onChange}
                          required
                        />
                        <div className="help">00-00 ~ FF-FF</div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>RTU 동작 모드(*)</Form.Label>
                      <Col>
                        <Form.Select
                          name="opMode"
                          value={formData.opMode}
                          onChange={onChange}
                          required
                        >
                          <option value="">-</option>
                          {Object.values(RtuOperationModeChoice).map(
                            (item, index) => (
                              <option key={index} value={item}>
                                {getRtuOperationModeName(item)}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>Reserved(*)</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="reserved"
                          value={formData.reserved}
                          onChange={onChange}
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>Ack 타입(*)</Form.Label>
                      <Col>
                        <Form.Select
                          name="ackType"
                          value={formData.ackType}
                          onChange={onChange}
                          required
                        >
                          {Object.values(RtuAckTypeChoice).map(
                            (item, index) => (
                              <option key={index} value={item}>
                                {getRtuAckTypeName(item)}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>Ack 예비1</Form.Label>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder=""
                          name="ackValue1"
                          value={
                            formData.ackValue1 !== null
                              ? formData.ackValue1
                              : ""
                          }
                          onChange={onChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>Ack 예비2</Form.Label>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder=""
                          name="ackValue2"
                          value={
                            formData.ackValue2 !== null
                              ? formData.ackValue2
                              : ""
                          }
                          onChange={onChange}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="group-box">
                    <h2>REMS Control Order</h2>
                    <Form.Group as={Row}>
                      <Form.Label column>에너지원 타입</Form.Label>
                      <Col>
                        <Form.Select
                          name="energyType"
                          value={formData.energyType || ""}
                          onChange={onChange}
                        >
                          <option value="">-</option>
                          {Object.values(EnergyTypeChoice).map(
                            (item, index) => (
                              <option key={index} value={item}>
                                {getEnergyTypeName(item)}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>멀티 연동 대수(최대 4개)</Form.Label>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder=""
                          name="multiCount"
                          value={
                            formData.multiCount !== null
                              ? formData.multiCount
                              : ""
                          }
                          onChange={onChange}
                          min={0}
                          max={4}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>설비 1의 연동 국번(0~255)</Form.Label>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder=""
                          name="multi1Address"
                          value={
                            formData.multi1Address !== null
                              ? formData.multi1Address
                              : ""
                          }
                          onChange={onChange}
                          min={0}
                          max={255}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>설비 2의 연동 국번(0~255)</Form.Label>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder=""
                          name="multi2Address"
                          value={
                            formData.multi2Address !== null
                              ? formData.multi2Address
                              : ""
                          }
                          onChange={onChange}
                          min={0}
                          max={255}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>설비 3의 연동 국번(0~255)</Form.Label>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder=""
                          name="multi3Address"
                          value={
                            formData.multi3Address !== null
                              ? formData.multi3Address
                              : ""
                          }
                          onChange={onChange}
                          min={0}
                          max={255}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>설비 4의 연동 국번(0~255)</Form.Label>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder=""
                          name="multi4Address"
                          value={
                            formData.multi4Address !== null
                              ? formData.multi4Address
                              : ""
                          }
                          onChange={onChange}
                          min={0}
                          max={255}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="group-box">
                    <h2>UART</h2>
                    <Form.Group as={Row}>
                      <Form.Label column>UART 인터페이스(*)</Form.Label>
                      <Col>
                        <Form.Select
                          name="uartInterface"
                          value={formData.uartInterface}
                          onChange={onChange}
                          required
                        >
                          <option value="">-</option>
                          {Object.values(UartInterfaceChoice).map(
                            (item, index) => (
                              <option key={index} value={item}>
                                {getUartInterfaceName(item)}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>UART Baudrate(*)</Form.Label>
                      <Col>
                        <Form.Select
                          name="uartBaudrate"
                          value={formData.uartBaudrate}
                          onChange={onChange}
                          required
                        >
                          <option value="">-</option>
                          {Object.values(UartBaudRateChoice).map(
                            (item, index) => (
                              <option key={index} value={item}>
                                {getUartBaudRateName(item)}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </div>
                </div>
                <div className="right-wrap">
                  <div className="group-box">
                    <h2>Polling Control Order</h2>
                    <Form.Group as={Row}>
                      <Form.Label column>Boot 완료 메시지 출력 여부</Form.Label>
                      <Col>
                        <Form.Select
                          name="bootMsg"
                          value={formData.bootMsg || ""}
                          onChange={onChange}
                        >
                          <option value="">-</option>
                          {Object.values(UseChoice).map((item, index) => (
                            <option key={index} value={item}>
                              {getUseName(item)}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>
                        UPLOAD 완료 메시지 출력 여부
                      </Form.Label>
                      <Col>
                        <Form.Select
                          name="uploadMsg"
                          value={formData.uploadMsg || ""}
                          onChange={onChange}
                        >
                          <option value="">-</option>
                          {Object.values(UseChoice).map((item, index) => (
                            <option key={index} value={item}>
                              {getUseName(item)}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>
                        설비 미 응답 메시지 전송 여부
                      </Form.Label>
                      <Col>
                        <Form.Select
                          name="noResponseMsg"
                          value={formData.noResponseMsg || ""}
                          onChange={onChange}
                        >
                          <option value="">-</option>
                          {Object.values(UseChoice).map((item, index) => (
                            <option key={index} value={item}>
                              {getUseName(item)}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>설비 Polling 쿼리 주기(분)</Form.Label>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder=""
                          name="period"
                          value={
                            formData.period !== null ? formData.period : ""
                          }
                          onChange={onChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>
                        설비 Polling 쿼리 실행 간격(초)
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder=""
                          name="interval"
                          value={
                            formData.interval !== null ? formData.interval : ""
                          }
                          onChange={onChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>
                        설비 Polling 쿼리 응답 대기 시간
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder=""
                          name="waitTime"
                          value={
                            formData.waitTime !== null ? formData.waitTime : ""
                          }
                          onChange={onChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>
                        실행할 Polling 쿼리 사용 개수(최대 20개)
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder=""
                          name="queryCount"
                          value={
                            formData.queryCount !== null
                              ? formData.queryCount
                              : ""
                          }
                          onChange={onChange}
                          min={0}
                          max={20}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>
                        응답 받은 데이터에 쿼리 Index 번호 구분 여부
                      </Form.Label>
                      <Col>
                        <Form.Select
                          name="queryIndex"
                          value={formData.queryIndex || ""}
                          onChange={onChange}
                        >
                          <option value="">-</option>
                          {Object.values(UseChoice).map((item, index) => (
                            <option key={index} value={item}>
                              {getUseName(item)}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="group-box">
                    <h2>Polling Query</h2>
                    <Form.Group as={Row}>
                      <Form.Label column>
                        연동된 설비에 실행할 쿼리 커맨드
                      </Form.Label>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Col>
                        <Form.Control
                          as="textarea"
                          rows={10}
                          placeholder=""
                          name="rtuQuery"
                          value={formData.rtuQuery || ""}
                          onChange={onChange}
                        />
                        <div className="help">쿼리 당 HEX 10byte 이내</div>
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="group-box">
                    <h2>Network</h2>
                    <Form.Group as={Row}>
                      <Form.Label column>서버 URL</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="serverUrl"
                          value={formData.serverUrl}
                          onChange={onChange}
                        />
                        <div className="help">IP 또는 URL</div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>서버 Port</Form.Label>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder=""
                          name="serverPort"
                          value={formData.serverPort}
                          onChange={onChange}
                          min={0}
                          max={65535}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="group-box">
                    <h2>FW Download</h2>
                    <Form.Group as={Row}>
                      <Form.Label column>펌웨어 다운로드 URL</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="fwDlUrl"
                          value={formData.fwDlUrl}
                          onChange={onChange}
                        />
                        <div className="help">{`ex) alliothub.com:30001`}</div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>펌웨어 다운로드 파일명</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="fwDlFileName"
                          value={formData.fwDlFileName || ""}
                          onChange={onChange}
                        />
                        <div className="help">
                          {`ex) SRTU_V1.1.1_220615_4FD8.bin`}
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>펌웨어 다운로드 접속 ID</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="fwDlId"
                          value={formData.fwDlId}
                          onChange={onChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>펌웨어 다운로드 접속 PW</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="fwDlPw"
                          value={formData.fwDlPw}
                          onChange={onChange}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
}
