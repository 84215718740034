import React, { useRef, useState } from "react";
import {
  Container,
  Form,
  InputGroup,
  Col,
  Row,
  Button,
  Table,
  Nav,
} from "react-bootstrap";
import "./RtuSendLogPage.scss";
import PaginatedItems from "components/common/PaginatedItems";
import * as rtuSendLogApi from "../apis/RtuSendLogApi";
import { apiWrapper } from "apis/CommonApi";
import { getRtuMsgTypeName } from "utils/common";
import Loading from "components/common/Loading";

export default function RtuSendLogPage() {
  const itemsPerPage = 100;
  const searchTextRef = useRef(null);
  const [dataSource, setDataSource] = useState([]);
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadExcel = (e) => {
    apiWrapper(async () => {
      const { rtuImei } = getSearchParams();
      const result = await rtuSendLogApi.exportExcelRtuSendLogs(
        rtuImei,
        page,
        itemsPerPage
      );

      const { status, data } = result;
      if (status === false) {
        alert(`엑셀 저장에 실패하였습니다.\n${data}`);
      }
    });
  };

  //page: 1베이스
  const getData = (page, url = null) => {
    apiWrapper(async () => {
      setIsLoading(true);
      let result;
      if (url !== null) {
        result = await rtuSendLogApi.getRtuSendLogsByUrl(url);
      } else {
        const { rtuImei } = getSearchParams();

        result = await rtuSendLogApi.getRtuSendLogs(
          rtuImei,
          page,
          itemsPerPage
        );
      }
      setIsLoading(false);

      const { status, data } = result;
      if (status === true) {
        setDataSource(data);

        setPrevious(data.previous);
        setNext(data.next);
        setPage(page);
      }
    });
  };

  const getSearchParams = () => {
    let params = { rtuImei: null };

    if (searchTextRef.current && searchTextRef.current?.value !== "") {
      params = { ...params, rtuImei: searchTextRef.current?.value };
    }

    return params;
  };

  const handlePageClick = (e) => {
    if (typeof e.nextSelectedPage !== "undefined") {
      const selectedPage = e.nextSelectedPage + 1;
      let url = null;
      if (e.isPrevious) {
        url = previous;
      } else if (e.isNext) {
        url = next;
      }

      getData(selectedPage, url);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getData(1);
  };

  return (
    <>
      <Container fluid className="mb-3 d-flex align-items-center">
        <h1>RTU 전송 로그</h1>
      </Container>

      <Container fluid className="mb-3 d-flex align-items-center">
        <Form onSubmit={handleSearch}>
          <Row className="align-items-center">
            <Col xs={"auto"}>
              <InputGroup>
                <InputGroup.Text>RTU IMEI</InputGroup.Text>
                <Form.Control
                  ref={searchTextRef}
                  style={{ width: "300px" }}
                  placeholder="검색할 문자열을 입력하세요."
                  required
                />
                <Button
                  variant="primary"
                  style={{ width: "130px" }}
                  type="submit"
                >
                  검색
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
        <span className="ms-auto">
          <Button
            className="ms-2"
            variant="primary"
            onClick={handleDownloadExcel}
          >
            엑셀 내려받기
          </Button>
        </span>
      </Container>

      {isLoading ? (
        <Loading />
      ) : (
        <div className="rtu-send-table">
          <div className="table-table">
            <Table
              bordered
              hover
              style={{ overflowX: "auto", whiteSpace: "nowrap" }}
            >
              <thead className="table-header">
                <tr>
                  <th>ID</th>
                  <th>RTU IMEI</th>
                  <th>서버 송신 시간</th>
                  <th>기기모델</th>
                  <th>메세지 타입</th>
                  <th>예비공간</th>
                  <th>예비공간</th>
                  <th>BODY 길이</th>
                  <th>BODY 옵션 ID</th>
                  <th>BODY 본문</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {dataSource.results &&
                  dataSource.results.map((item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.rtuImei}</td>
                      <td>{item.time}</td>
                      <td>{item.deviceModel}</td>
                      <td>{getRtuMsgTypeName(item.msgType)}</td>
                      <td>{item.reserved1}</td>
                      <td>{item.reserved2}</td>
                      <td>{item.bodyLength}</td>
                      <td>{item.bodyOptionId}</td>
                      <td style={{ textAlign: "left" }}>{item.body}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div style={{ marginTop: 20 }}>
            <PaginatedItems
              totalItemsCount={dataSource.count}
              itemsPerPage={itemsPerPage}
              selectedPage={page - 1}
              onClick={handlePageClick}
            />
          </div>
        </div>
      )}
    </>
  );
}
