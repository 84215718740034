import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import "./UARTSendComponent.scss";
import { apiWrapper } from "apis/CommonApi";
import * as rtuRequestApi from "../../apis/RtuRequestApi";
import { DataTypeChoice, getDataTypeName } from "utils/common";

export default function UARTSendComponent(props) {
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    setFormDataProperty("rtuImei", "");
    setFormDataProperty("dataType", "0");
    setFormDataProperty("uartTxData", "");
  }, []);

  const setFormDataProperty = (name, value) => {
    setFormData((currentFormData) => {
      const nextFormData = {
        ...currentFormData,
        [name]: value,
      };
      //console.log(nextFormData);
      return nextFormData;
    });
  };

  const onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setFormDataProperty(name, value);
  };

  const handleSend = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      apiWrapper(async () => {
        let requestBody = {
          rtuImei: formData.rtuImei,
          dataType: formData.dataType,
          uartTxData: formData.uartTxData,
        };
        //console.log(JSON.stringify(requestBody));
        let result = await rtuRequestApi.sendRtuUART(requestBody);
        const { status, data } = result;
        if (status === true) {
          alert("전송 성공하였습니다.");
          props.onHide();
        } else {
          alert(`전송 실패하였습니다.\n${data}`);
        }
      });
    }
  };

  return (
    <>
      <Modal
        {...props}
        show
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="external-api-integration modal-30w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            UART 전송 (Server-{">"}RTU Text Data 전송)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData && (
            <Form onSubmit={handleSend}>
              <div className="mb-3">
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    대상 RTU IMEI
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="rtuImei"
                      value={formData.rtuImei}
                      onChange={onChange}
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Data 형식
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Select
                      name="dataType"
                      value={formData.dataType}
                      onChange={onChange}
                      required
                    >
                      {Object.values(DataTypeChoice).map((item, index) => (
                        <option key={index} value={item}>
                          {getDataTypeName(item)}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Control
                  as="textarea"
                  rows={10}
                  placeholder=""
                  name="uartTxData"
                  value={formData.uartTxData}
                  onChange={onChange}
                />
              </div>
              <div className="d-flex justify-content-center">
                <Button variant="primary" type="submit" size="lg">
                  전송
                </Button>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
