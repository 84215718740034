import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "./store";

export default function LoginRequiredRoute({ children }) {
  let isAuthenticated = false;
  const location = useLocation();
  const {
    store: { access },
  } = useAppContext();

  isAuthenticated = access !== "";

  if (!isAuthenticated) {
    return <Navigate to="/login" state={location} />;
  }

  return children;
}
