import { axiosInstance, parseResult } from "./CommonApi";

export const login = async (userId, password) => {
  const params = { userId: userId, password: password };
  const response = await axiosInstance.post(`/accounts/auth/pair/`, params);
  return parseResult(response);
};

//유저 정보 조회
export const getUser = async () => {
  const response = await axiosInstance.get(`/accounts/user/`);
  return parseResult(response);
};
