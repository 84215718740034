import React, { useRef, useState } from "react";
import {
  Container,
  Form,
  InputGroup,
  Col,
  Row,
  Button,
  Table,
  Nav,
} from "react-bootstrap";
import "./RemsSendLogPage.scss";
import PaginatedItems from "components/common/PaginatedItems";
import { apiWrapper } from "apis/CommonApi";
import * as remsSendLogApi from "../apis/RemsSendLogApi";
import { getMultiIdName, getSendSuccessFailName } from "utils/common";
import Loading from "components/common/Loading";

const SearchOptions = {
  RTU_IMEI: "0",
  CID: "1",
};

const getSearchOptionName = (value) => {
  let name = "";
  const key = Object.keys(SearchOptions)[value];
  switch (key) {
    case "RTU_IMEI":
      name = "RTU IMEI";
      break;
    case "CID":
      name = "CID";
      break;
    default:
      break;
  }
  return name;
};

export default function RemsSendLogPage() {
  const itemsPerPage = 100;
  const searchTextRef = useRef(null);
  const [dataSource, setDataSource] = useState([]);
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchOption, setSearchOption] = useState(SearchOptions.RTU_IMEI);

  const handleDownloadExcel = (e) => {
    apiWrapper(async () => {
      const { rtuImei } = getSearchParams();
      const result = await remsSendLogApi.exportExcelRemsSendLogs(
        rtuImei,
        page,
        itemsPerPage
      );

      const { status, data } = result;
      if (status === false) {
        alert(`엑셀 저장에 실패하였습니다.\n${data}`);
      }
    });
  };

  //page: 1베이스
  const getData = (page, url = null) => {
    apiWrapper(async () => {
      setIsLoading(true);
      let result;
      if (url !== null) {
        result = await remsSendLogApi.getRemsSendLogsByUrl(url);
      } else {
        const { rtuImei, cid } = getSearchParams();

        result = await remsSendLogApi.getRemsSendLogs(
          rtuImei,
          cid,
          page,
          itemsPerPage
        );
      }
      setIsLoading(false);

      const { status, data } = result;
      if (status === true) {
        setDataSource(data);

        setPrevious(data.previous);
        setNext(data.next);
        setPage(page);
      }
    });
  };

  const getSearchParams = () => {
    let rtuImei = null;
    let cid = null;

    if (searchTextRef.current && searchTextRef.current?.value !== "") {
      const searchText = searchTextRef.current?.value;
      switch (searchOption) {
        case SearchOptions.RTU_IMEI:
          rtuImei = searchText;
          break;
        case SearchOptions.CID:
          cid = searchText;
          break;
        default:
          break;
      }
    }

    return { rtuImei: rtuImei, cid: cid };
  };

  const handlePageClick = (e) => {
    if (typeof e.nextSelectedPage !== "undefined") {
      const selectedPage = e.nextSelectedPage + 1;
      let url = null;
      if (e.isPrevious) {
        url = previous;
      } else if (e.isNext) {
        url = next;
      }

      getData(selectedPage, url);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getData(1);
  };

  const handleChangeSearchOption = (e) => {
    setSearchOption(e.target.value);
  };

  return (
    <>
      <Container fluid className="mb-3 d-flex align-items-center">
        <h1>REMS 전송 로그</h1>
      </Container>

      <Container fluid className="mb-3 d-flex align-items-center">
        <Form onSubmit={handleSearch}>
          <Row className="align-items-center">
            <Col xs={"auto"}>
              <InputGroup>
                <Form.Select
                  style={{ width: "150px" }}
                  value={searchOption}
                  onChange={handleChangeSearchOption}
                >
                  {Object.values(SearchOptions).map((item, index) => (
                    <option key={index} value={item}>
                      {getSearchOptionName(item)}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control
                  ref={searchTextRef}
                  style={{ width: "300px" }}
                  placeholder="검색할 문자열을 입력하세요."
                  required
                />
                <Button
                  variant="primary"
                  style={{ width: "130px" }}
                  type="submit"
                >
                  검색
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
        <span className="ms-auto">
          <Button
            className="ms-2"
            variant="primary"
            onClick={handleDownloadExcel}
          >
            엑셀 내려받기
          </Button>
        </span>
      </Container>

      {isLoading ? (
        <Loading />
      ) : (
        <div className="rems-send-table">
          <div className="table-table">
            <Table
              bordered
              hover
              style={{ overflowX: "auto", whiteSpace: "nowrap" }}
            >
              <thead className="table-header">
                <tr>
                  <th>ID</th>
                  <th>RTU IMEI</th>
                  <th>서버 송신 시간</th>
                  <th>MULTI ID</th>
                  <th>CID</th>
                  <th>전송 결과</th>
                  <th>에러 내용</th>
                  <th>BODY 본문</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {dataSource.results &&
                  dataSource.results.map((item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.rtuImei}</td>
                      <td>{item.time}</td>
                      <td>{item.multiId}</td>
                      <td>{item.cid}</td>
                      <td>{getSendSuccessFailName(item.result)}</td>
                      <td>{item.error}</td>
                      <td style={{ textAlign: "left" }}>{item.body}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div style={{ marginTop: 20 }}>
            <PaginatedItems
              totalItemsCount={dataSource.count}
              itemsPerPage={itemsPerPage}
              selectedPage={page - 1}
              onClick={handlePageClick}
            />
          </div>
        </div>
      )}
    </>
  );
}
