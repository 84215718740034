import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
  Pagination,
  InputGroup,
  Container,
} from "react-bootstrap";
import * as externalApiApi from "apis/ExternalApiApi";
import AddExternalApiParameterComponent from "../components/external-api/AddExternalApiParameterComponent";
import "./ExternalApiParameterPage.scss";
import PaginatedItems from "components/common/PaginatedItems";
import { apiWrapper } from "apis/CommonApi";
import { getDateTime } from "utils/date";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "components/common/Loading";

export default function ExternalApiParameterPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchTextRef = useRef(null);
  const itemsPerPage = 100;
  const [modalShow, setModalShow] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData(1);

    const state = {};
    const title = "";
    let url = "/exapi/parameter/";
    window.history.pushState(state, title, url);
    window.onpopstate = handleBack;
    return () => {
      window.onpopstate = null;
    };
  }, []);

  const handleBack = () => {
    navigate(location.state?.prevUrl, {
      state: { condition: location.state?.condition },
    });
  };

  const handlePageClick = (e) => {
    if (typeof e.nextSelectedPage !== "undefined") {
      const selectedPage = e.nextSelectedPage + 1;
      let url = null;
      if (e.isPrevious) {
        url = previous;
      } else if (e.isNext) {
        url = next;
      }

      getData(selectedPage, url);
    }
  };

  //page: 1베이스
  const getData = (page, url = null) => {
    apiWrapper(async () => {
      setIsLoading(true);
      let result;
      if (url !== null) {
        result = await externalApiApi.getExternalApiParametersByUrl(url);
      } else {
        let aname = null;
        if (searchTextRef.current && searchTextRef.current?.value !== "") {
          const searchText = searchTextRef.current?.value;
          //API 업체명으로 검색
          aname = searchText;
        }

        result = await externalApiApi.getExternalApiParameters(
          aname,
          page,
          itemsPerPage
        );
      }
      setIsLoading(false);

      const { status, data } = result;
      if (status === true) {
        setDataSource(data);
        const offset = ((page - 1) * itemsPerPage) % data.count;
        setItemOffset(offset);
        setPrevious(data.previous);
        setNext(data.next);
        setPage(page);
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getData(1);
  };

  const handleAdd = () => {
    setSelectedItem(null);
    setModalShow(true);
  };

  const handleModify = (item) => {
    setSelectedItem(item);
    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
    getData(page);
  };

  return (
    <>
      {modalShow && (
        <AddExternalApiParameterComponent
          onHide={handleClose}
          item={selectedItem}
        />
      )}
      <Container fluid className="mb-3 d-flex align-items-center">
        <h1>외부 전송 API 관리 {">"} API 파라미터</h1>
      </Container>

      <Container fluid className="mb-3 d-flex align-items-center">
        <Form onSubmit={handleSearch}>
          <Row className="align-items-center">
            <Col xs={"auto"}>
              <InputGroup>
                <InputGroup.Text>API 업체명</InputGroup.Text>
                <Form.Control
                  ref={searchTextRef}
                  style={{ width: "300px" }}
                  placeholder="검색할 문자열을 입력하세요."
                />
                <Button
                  variant="primary"
                  style={{ width: "130px" }}
                  type="submit"
                >
                  검색
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
        <span className="ms-auto">
          <Button className="ms-2" variant="primary" onClick={handleAdd}>
            신규작성
          </Button>
        </span>
      </Container>

      {isLoading ? (
        <Loading />
      ) : (
        <div className="external-api-parameter">
          <div className="table-table mb-3">
            <Table bordered hover style={{ textAlign: "center" }}>
              <thead className="table-header">
                <tr>
                  <th>No</th>
                  <th>API 관리코드</th>
                  <th>API 업체명</th>
                  <th>Destination URL</th>
                  <th>Port</th>
                  <th>ID</th>
                  <th>PW</th>
                  <th>작성 일시</th>
                </tr>
              </thead>
              <tbody>
                {dataSource.results &&
                  dataSource.results.map((item, index) => (
                    <tr key={index}>
                      <td>{itemOffset + (index + 1)}</td>
                      <td onDoubleClick={() => handleModify(item)}>
                        {item.id}
                      </td>
                      <td>{item.companyName}</td>
                      <td>{item.serverUrl}</td>
                      <td>{item.serverPort}</td>
                      <td>{item.serverId}</td>
                      <td>{item.serverPassword}</td>
                      <td>
                        {item.writeDate &&
                          getDateTime(new Date(item.writeDate))}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <PaginatedItems
            totalItemsCount={dataSource.count}
            itemsPerPage={itemsPerPage}
            selectedPage={page - 1}
            onClick={handlePageClick}
          />
        </div>
      )}
    </>
  );
}
