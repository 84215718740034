import RemsInterlockAddDetailPage from "pages/RemsInterlockAddDetailPage";
import React from "react";
import { Route, Routes } from "react-router-dom";
import RemsInterlockAddPage from "pages/RemsInterlockAddPage";

export default function RemsInterlockAddRoutes() {
  return (
    <>
      <Routes>
        <Route path="*" element={<RemsInterlockAddPage />} />
        <Route path="/detail/" element={<RemsInterlockAddDetailPage />} />
        <Route path="/detail/:id/" element={<RemsInterlockAddDetailPage />} />
      </Routes>
    </>
  );
}
