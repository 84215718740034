import ExternalApiPage from "pages/ExternalApiPage";
import ExternalApiParameterPage from "pages/ExternalApiParameterPage";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthorityCheckRoute from "utils/AuthorityCheckRoute";
import { UserAuthorityChoice } from "utils/common";

export default function ExternalApiRoutes() {
  return (
    <>
      <Routes>
        <Route path="*" element={<ExternalApiPage />} />
        <Route
          path="/parameter/"
          element={
            <AuthorityCheckRoute authority={[UserAuthorityChoice.ADMIN]}>
              <ExternalApiParameterPage />
            </AuthorityCheckRoute>
          }
        />
      </Routes>
    </>
  );
}
