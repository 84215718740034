import { apiWrapper } from "apis/CommonApi";
import PaginatedItems from "components/common/PaginatedItems";
import AddExternalApiIntegrationComponent from "components/external-api/AddExternalApiIntegrationComponent";
import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Form,
  InputGroup,
  Col,
  Row,
  Button,
  Table,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./ExternalApiPage.scss";
import * as externalApi from "apis/ExternalApiApi";
import { getDateTime } from "utils/date";
import AuthorityCheckRoute from "utils/AuthorityCheckRoute";
import { UserAuthorityChoice } from "utils/common";
import Loading from "components/common/Loading";

const SearchOptions = {
  RTU_IMEI: "0",
  COMPANY_NAME: "1",
};

const getSearchOptionName = (value) => {
  let name = "";
  const key = Object.keys(SearchOptions)[value];
  switch (key) {
    case "RTU_IMEI":
      name = "RTU IMEI";
      break;
    case "COMPANY_NAME":
      name = "API 업체명";
      break;
    default:
      break;
  }
  return name;
};

export default function ExternalApiPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchTextRef = useRef(null);
  const itemsPerPage = 100;
  const [modalShowIntegration, setModalShowIntegration] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const [page, setPage] = useState(1);
  const [searchOption, setSearchOption] = useState(SearchOptions.RTU_IMEI);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (location.state?.condition) {
      const condition = location.state?.condition;
      setSearchOption(condition.searchOption);
      if (searchTextRef) {
        searchTextRef.current.value = condition.searchText;
      }
    }

    getData(1);
  }, []);

  const handlePageClick = (e) => {
    if (typeof e.nextSelectedPage !== "undefined") {
      const selectedPage = e.nextSelectedPage + 1;
      let url = null;
      if (e.isPrevious) {
        url = previous;
      } else if (e.isNext) {
        url = next;
      }

      getData(selectedPage, url);
    }
  };

  //page: 1베이스
  const getData = (page, url = null) => {
    apiWrapper(async () => {
      setIsLoading(true);
      let result;
      if (url !== null) {
        result = await externalApi.getExternalApisByUrl(url);
      } else {
        const { rname, cname } = getSearchParams();
        result = await externalApi.getExternalApis(
          rname,
          cname,
          page,
          itemsPerPage
        );
      }
      setIsLoading(false);

      const { status, data } = result;
      if (status === true) {
        setDataSource(data);
        const offset = ((page - 1) * itemsPerPage) % data.count;
        setItemOffset(offset);
        setPrevious(data.previous);
        setNext(data.next);
        setPage(page);
      }
    });
  };

  const getSearchParams = () => {
    let rname = null;
    let cname = null;

    if (searchTextRef.current && searchTextRef.current?.value !== "") {
      const searchText = searchTextRef.current?.value;
      switch (searchOption) {
        case SearchOptions.RTU_IMEI:
          rname = searchText;
          break;
        case SearchOptions.COMPANY_NAME:
          cname = searchText;
          break;
        default:
          break;
      }
    }

    return { rname: rname, cname: cname };
  };

  const handleDownloadExcel = (e) => {
    apiWrapper(async () => {
      const { rname, cname } = getSearchParams();
      const result = await externalApi.exportExcelExternalApiList(
        rname,
        cname,
        page,
        itemsPerPage
      );

      const { status, data } = result;
      if (status === false) {
        alert(`엑셀 저장에 실패하였습니다.\n${data}`);
      }
    });
  };

  const handleApiIntegration = () => {
    setModalShowIntegration(true);
  };

  const handleAddApiParameter = () => {
    navigate("/exapi/parameter/", {
      state: {
        prevUrl: location.pathname,
        condition: {
          searchOption: searchOption,
          searchText: searchTextRef.current?.value,
        },
      },
    });
  };

  const handleCloseIntegration = () => {
    setModalShowIntegration(false);
    getData(page);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getData(1);
  };

  const handleChangeSearchOption = (e) => {
    setSearchOption(e.target.value);
  };

  return (
    <>
      {modalShowIntegration && (
        <AddExternalApiIntegrationComponent onHide={handleCloseIntegration} />
      )}
      <Container fluid className="mb-3 d-flex align-items-center">
        <h1>외부 전송 API 관리</h1>
        <span className="ms-auto">
          <label className="ms-2">
            조회 시간 : {getDateTime(new Date())} 기준
          </label>
        </span>
      </Container>

      <Container fluid className="mb-3 d-flex align-items-center">
        <Form onSubmit={handleSearch}>
          <Row className="align-items-center">
            <Col xs={"auto"}>
              <InputGroup>
                <Form.Select
                  style={{ width: "150px" }}
                  value={searchOption}
                  onChange={handleChangeSearchOption}
                >
                  {Object.values(SearchOptions).map((item, index) => (
                    <option key={index} value={item}>
                      {getSearchOptionName(item)}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control
                  ref={searchTextRef}
                  style={{ width: "300px" }}
                  placeholder="검색할 문자열을 입력하세요."
                />
                <Button
                  variant="primary"
                  style={{ width: "130px" }}
                  type="submit"
                >
                  검색
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
        <span className="ms-auto">
          <Button
            className="ms-2"
            variant="primary"
            onClick={handleDownloadExcel}
          >
            엑셀 내려받기
          </Button>
          <Button
            className="ms-2"
            variant="primary"
            onClick={handleApiIntegration}
          >
            API 연동 등록
          </Button>
          <AuthorityCheckRoute authority={[UserAuthorityChoice.ADMIN]}>
            <Button
              className="ms-2"
              variant="primary"
              onClick={handleAddApiParameter}
            >
              API 파라미터
            </Button>
          </AuthorityCheckRoute>
        </span>
      </Container>

      {isLoading ? (
        <Loading />
      ) : (
        <div className="exapi-table">
          <div className="table-table">
            <Table bordered hover>
              <thead className="table-header">
                <tr>
                  <th>No</th>
                  <th>RTU IMEI</th>
                  <th>API 관리코드</th>
                  <th>API 업체명</th>
                  <th>Destination URL</th>
                  <th>등록자</th>
                  <th>등록일시</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {dataSource.results &&
                  dataSource.results.map((item, index) => (
                    <tr key={index}>
                      <td>{itemOffset + (index + 1)}</td>
                      <td>{item.rtuImei}</td>
                      <td>{item.manageCode}</td>
                      <td>{item.companyName}</td>
                      <td>{item.serverUrl}</td>
                      <td>{item.writer}</td>
                      <td>{item.writeDate}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div style={{ marginTop: 20 }}>
            <PaginatedItems
              totalItemsCount={dataSource.count}
              itemsPerPage={itemsPerPage}
              selectedPage={page - 1}
              onClick={handlePageClick}
            />
          </div>
        </div>
      )}
    </>
  );
}
