import { axiosInstance, parseResult } from "./CommonApi";

//외부 API 리스트 조회
export const getExternalApis = async (rname, cname, page, pageSize) => {
  const params = {
    rname: rname,
    cname: cname,
    page: page,
    page_size: pageSize,
  };
  const response = await axiosInstance.get(`/exapi/`, {
    params,
  });
  return parseResult(response);
};

export const getExternalApisByUrl = async (url) => {
  const response = await axiosInstance.get(url);
  return parseResult(response);
};

//외부 API 리스트 엑셀 저장
export const exportExcelExternalApiList = async (rname, gname) => {
  const params = { rname: rname, gname: gname };
  const response = await axiosInstance.post(
    `/exapi/export/`,
    {},
    { params, responseType: "blob" }
  );

  const result = parseResult(response);
  const { status, data } = result;
  if (status === true) {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers["content-type"] })
    );
    const filename =
      response.headers["content-disposition"]?.split("filename=")[1];

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return result;
};

//외부 API 연동 생성
export const createExternalApiInterlock = async (requestBody) => {
  const response = await axiosInstance.post(`/exapi/interlock/`, requestBody);
  return parseResult(response);
};

//외부 API 파라미터 생성
export const createExternalApiParameter = async (requestBody) => {
  const response = await axiosInstance.post(`/exapi/param/`, requestBody);
  return parseResult(response);
};

//외부 API 리스트 조회
export const getExternalApiParameters = async (aname, page, pageSize) => {
  const params = {
    aname: aname,
    page: page,
    page_size: pageSize,
  };
  const response = await axiosInstance.get(`/exapi/param/`, {
    params,
  });
  return parseResult(response);
};

export const getExternalApiParametersByUrl = async (url) => {
  const response = await axiosInstance.get(url);
  return parseResult(response);
};

//외부 API 조회
export const getExternalApiParameter = async (id) => {
  const response = await axiosInstance.get(`/exapi/param/${id}/`);
  return parseResult(response);
};

//외부 API 리스트 수정
export const updateExternalApiParameter = async (id, requestBody) => {
  const response = await axiosInstance.put(`/exapi/param/${id}/`, requestBody);
  return parseResult(response);
};

//외부 API 전체 조회
export const getAllExternalApiParameters = async () => {
  const response = await axiosInstance.get(`/exapi/param/list/all/`);
  return parseResult(response);
};
