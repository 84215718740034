import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Form,
  InputGroup,
  Col,
  Row,
  Button,
  Table,
} from "react-bootstrap";
import "./RemsInterlockAddPage.scss";
import PaginatedItems from "components/common/PaginatedItems";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as remsInterlockAddApi from "../apis/RemsInterlockAddApi";
import { apiWrapper } from "apis/CommonApi";
import { SERVER_HOST } from "Constants";
import { getMultiIdName } from "utils/common";
import Loading from "components/common/Loading";

export default function RemsInterlockAddPage() {
  const itemsPerPage = 100;
  const navigate = useNavigate();
  const location = useLocation();
  const searchTextRef = useRef(null);
  const fileInputRef = useRef(null);
  const [dataSource, setDataSource] = useState([]);
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const [page, setPage] = useState(1);
  const [checkIds, setCheckIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (location.state?.condition) {
      const condition = location.state?.condition;
      if (searchTextRef) {
        searchTextRef.current.value = condition.rtuImei;
      }
    }

    getData(1);
  }, []);

  //page: 1베이스
  const getData = (page, url = null) => {
    apiWrapper(async () => {
      setIsLoading(true);
      let result;
      if (url !== null) {
        result = await remsInterlockAddApi.getRemsInterlocksByUrl(url);
      } else {
        const { rtuImei } = getSearchParams();
        result = await remsInterlockAddApi.getRemsInterlocks(
          rtuImei,
          page,
          itemsPerPage
        );
      }
      setIsLoading(false);

      const { status, data } = result;
      if (status === true) {
        setDataSource(data);
        setPrevious(data.previous);
        setNext(data.next);
        setPage(page);
      }
    });
  };

  const getSearchParams = () => {
    let params = { rtuImei: null };

    if (searchTextRef.current && searchTextRef.current?.value !== "") {
      params = { ...params, rtuImei: searchTextRef.current?.value };
    }

    return params;
  };

  const handlePageClick = (e) => {
    if (typeof e.nextSelectedPage !== "undefined") {
      const selectedPage = e.nextSelectedPage + 1;
      let url = null;
      if (e.isPrevious) {
        url = previous;
      } else if (e.isNext) {
        url = next;
      }

      getData(selectedPage, url);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getData(1);
  };

  const handleChangeCheckAll = (e) => {
    if (dataSource.results) {
      if (e.target.checked) {
        //전체 체크
        let allIds = [];
        dataSource.results.map((item, index) => {
          allIds.push(item.id);
        });

        setCheckIds(allIds);
      } else {
        //전체 체크 해제
        setCheckIds([]);
      }
    }
  };

  const handleChangeCheck = (e, id) => {
    if (e.target.checked) {
      setCheckIds([...checkIds, id]);
    } else {
      setCheckIds(checkIds.filter((a) => a !== id));
    }
  };

  const handleDownloadExcel = (e) => {
    apiWrapper(async () => {
      const { rtuImei } = getSearchParams();
      const result = await remsInterlockAddApi.exportExcelRemsInterlocks(
        rtuImei,
        page,
        itemsPerPage
      );

      const { status, data } = result;
      if (status === false) {
        alert(`엑셀 저장에 실패하였습니다.\n${data}`);
      }
    });
  };

  const handleUpload = (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    if (file) {
      const requestBody = new FormData();
      requestBody.append("file", file);

      apiWrapper(async () => {
        let result = await remsInterlockAddApi.importExcel(requestBody);

        const { status, data } = result;
        if (status === true) {
          alert("파일 일괄등록 성공하였습니다.");

          getData(1);
        } else {
          alert(`파일 일괄등록 실패하였습니다.\n${data}`);
        }
      });
    }
  };

  const handleAdd = (e) => {
    navigate("/rems-interlock-add/detail/", {
      state: {
        prevUrl: location.pathname,
        condition: { rtuImei: searchTextRef.current?.value },
      },
    });
  };

  const handleDelete = (e) => {
    if (checkIds.length < 1) {
      alert("삭제할 항목을 선택하세요.");
    } else if (window.confirm("삭제하시겠습니까?")) {
      apiWrapper(async () => {
        const requestBody = { ids: checkIds };
        const result = await remsInterlockAddApi.deleteRemsInterlocks(
          requestBody
        );

        const { status, data } = result;
        if (status === true) {
          alert("삭제되었습니다.");
          getData(page);
        } else {
          alert(`삭제 실패하였습니다.\n${data}`);
        }
      });
    }
  };

  return (
    <>
      <Container fluid className="mb-3 d-flex align-items-center">
        <h1>REMS 연동 등록</h1>
      </Container>

      <Container fluid className="mb-3 d-flex align-items-center">
        <Form onSubmit={handleSearch}>
          <Row className="align-items-center">
            <Col xs={"auto"}>
              <InputGroup>
                <InputGroup.Text>RTU IMEI</InputGroup.Text>
                <Form.Control
                  ref={searchTextRef}
                  style={{ width: "300px" }}
                  placeholder="검색할 문자열을 입력하세요."
                />
                <Button
                  variant="primary"
                  style={{ width: "130px" }}
                  type="submit"
                >
                  검색
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
        <span className="ms-auto">
          <Button
            className="ms-2"
            variant="primary"
            onClick={handleDownloadExcel}
          >
            엑셀 내려받기
          </Button>
          <a href={SERVER_HOST + `/media/sample/alliothub_rems_sample.xlsx`}>
            <Button className="ms-2" variant="primary">
              샘플
            </Button>
          </a>
          <Button
            className="ms-2"
            variant="primary"
            onClick={() => fileInputRef.current?.click()}
          >
            파일 추가
          </Button>
          <input
            ref={fileInputRef}
            style={{ display: "none" }}
            type="file"
            accept=".xlsx"
            onChange={handleUpload}
          />
          <Button className="ms-2" variant="primary" onClick={handleAdd}>
            추가
          </Button>
          <Button className="ms-2" variant="primary" onClick={handleDelete}>
            삭제
          </Button>
        </span>
      </Container>

      {isLoading ? (
        <Loading />
      ) : (
        <div className="rems-inte-table">
          <div className="table-table">
            <Table
              bordered
              hover
              style={{ overflowX: "auto", whiteSpace: "nowrap" }}
            >
              <thead className="table-header">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      style={{ zoom: 1.0 }}
                      className="form-check-input"
                      onChange={handleChangeCheckAll}
                    />
                  </th>
                  <th>ID</th>
                  <th>RTU IMEI</th>
                  <th>CID</th>
                  <th>MULTI ID</th>
                  <th>설치자</th>
                  <th>설치자 연락처</th>
                  <th>주소</th>
                  <th>설비회사</th>
                  <th>모니터링 업체</th>
                  <th>인증키</th>
                  <th>토큰</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {dataSource.results &&
                  dataSource.results.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          style={{ zoom: 1.0 }}
                          className="form-check-input"
                          onChange={(e) => handleChangeCheck(e, item.id)}
                          checked={checkIds.includes(item.id)}
                        />
                      </td>
                      <td>
                        <Link
                          to={`/rems-interlock-add/detail/${item.id}`}
                          state={{
                            id: item.id,
                            prevUrl: location.pathname,
                            condition: {
                              rtuImei: searchTextRef.current?.value,
                            },
                          }}
                        >
                          {item.id}
                        </Link>
                      </td>
                      <td>{item.rtuImei}</td>
                      <td>{item.cid}</td>
                      <td>{getMultiIdName(item.multiId)}</td>
                      <td>{item.worker}</td>
                      <td>{item.phoneNumber}</td>
                      <td>{item.address}</td>
                      <td>{item.facCompany}</td>
                      <td>{item.monitorCompany}</td>
                      <td>{item.authKey}</td>
                      <td>{item.token}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div style={{ marginTop: 20 }}>
            <PaginatedItems
              totalItemsCount={dataSource.count}
              itemsPerPage={itemsPerPage}
              selectedPage={page - 1}
              onClick={handlePageClick}
            />
          </div>
        </div>
      )}
    </>
  );
}
