import { axiosInstance, parseResult } from "./CommonApi";

//RTU 요청 리스트 조회
export const getRtuRequests = async (
  rtuImei,
  deviceDivision,
  businessId,
  serviceId,
  page,
  pageSize
) => {
  const params = {
    rtu_imei: rtuImei,
    device_division: deviceDivision,
    business_id: businessId,
    service_id: serviceId,
    page: page,
    page_size: pageSize,
  };
  const response = await axiosInstance.get(`/rtu/request/`, {
    params,
  });
  return parseResult(response);
};

export const getRtuRequestsByUrl = async (url) => {
  const response = await axiosInstance.get(url);
  return parseResult(response);
};

//UART 전송
export const sendRtuUART = async (requestBody) => {
  const response = await axiosInstance.post(`/rtu/request/uart/`, requestBody);
  return parseResult(response);
};

//요청
export const requestRtu = async (requestBody) => {
  const response = await axiosInstance.post(
    `/rtu/request/request/`,
    requestBody
  );
  return parseResult(response);
};
