import { axiosInstance, parseResult } from "./CommonApi";

//RTU IMEI 리스트 조회
export const getAllRtuImeis = async () => {
  const response = await axiosInstance.get(`/rtu/rtu-imei/`);
  return parseResult(response);
};

//RTU IMEI 리스트 조회
export const getSettingRtuImeis = async () => {
  const response = await axiosInstance.get(`/rtu/rtu-imei/setting/`);
  return parseResult(response);
};

//RTU 설정 생성
export const createRtuSetting = async (requestBody) => {
  const response = await axiosInstance.post(`/rtu/setting/`, requestBody);
  return parseResult(response);
};

//RTU 설정 리스트 조회
export const getRtuSettings = async (rtuImei, page, pageSize) => {
  const params = {
    rtu_imei: rtuImei,
    page: page,
    page_size: pageSize,
  };
  const response = await axiosInstance.get(`/rtu/setting/`, {
    params,
  });
  return parseResult(response);
};

export const getRtuSettingsByUrl = async (url) => {
  const response = await axiosInstance.get(url);
  return parseResult(response);
};

//RTU 설정 조회
export const getRtuSetting = async (id) => {
  const response = await axiosInstance.get(`/rtu/setting/${id}/`);
  return parseResult(response);
};

//외부 API 리스트 수정
export const updateRtuSetting = async (id, requestBody) => {
  const response = await axiosInstance.put(`/rtu/setting/${id}/`, requestBody);
  return parseResult(response);
};

//RTU 설정 삭제
export const deleteRtuSetting = async (id) => {
  const response = await axiosInstance.delete(`/rtu/setting/${id}/`, {});
  return parseResult(response);
};

//RTU 설정 삭제
export const deleteRtuSettings = async (requestBody) => {
  const response = await axiosInstance.delete(`/rtu/setting/list/delete/`, {
    data: requestBody,
  });
  return parseResult(response);
};

//RTU 설정 리스트 엑셀 저장
export const exportExcelRtuSettings = async (rtuImei) => {
  const params = { rtu_imei: rtuImei };
  const response = await axiosInstance.post(
    `/rtu/setting/list/export/`,
    {},
    { params, responseType: "blob" }
  );

  const result = parseResult(response);
  const { status, data } = result;
  if (status === true) {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers["content-type"] })
    );
    const filename =
      response.headers["content-disposition"]?.split("filename=")[1];

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return result;
};

//RTU 설정 리스트 엑셀 업로드
export const importExcel = async (requestBody) => {
  const response = await axiosInstance.post(
    `/rtu/setting/list/import/`,
    requestBody,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return parseResult(response);
};
