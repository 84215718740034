import { useState } from "react";

export function getSessionStorageItem(key, initialValue) {
  try {
    // Get from local storage by key
    const item = window.sessionStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    // If error also return initialValue
    console.log(error);
    return initialValue;
  }
}

export function setSessionStorageItem(key, value) {
  try {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
}
