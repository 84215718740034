import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import * as XLSX from "xlsx";
import * as externalApiApi from "apis/ExternalApiApi";
import "./AddExternalApiIntegrationComponent.scss";
import { getDate, getTime } from "utils/date";
import { apiWrapper } from "apis/CommonApi";
import { SERVER_HOST } from "Constants";

export default function AddExternalApiIntegrationComponent(props) {
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState(null);
  const [rtuList, setRtuList] = useState([]);
  const [parameterList, setParameterList] = useState(null);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    apiWrapper(async () => {
      let result = await externalApiApi.getAllExternalApiParameters();

      const { status, data } = result;
      if (status === true) {
        setParameterList(data);
      }
    });

    setFormDataProperty("integrationApi", "0");
    setFormDataProperty("writer", "");
    setFormDataProperty("writeDate", getDate(new Date()));
    setFormDataProperty("rtuCount", 0);
    setFormDataProperty("rtuList", rtuList);
  }, []);

  const handleChangeRtuList = (e, index) => {
    let name = e.target.name;
    let value = e.target.value;

    let newRtuList = formData.rtuList.map((item, changedIndex) =>
      changedIndex === index ? value : item
    );

    setFormDataProperty(name, newRtuList);
  };

  const setFormDataProperty = (name, value) => {
    setFormData((currentFormData) => {
      const nextFormData = {
        ...currentFormData,
        [name]: value,
      };
      //console.log(nextFormData);
      return nextFormData;
    });
  };

  const onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFormDataProperty(name, value);

    if (name === "rtuCount") {
      //대상 RTU 수량 변경 시 테이블 Row 수 변경
      const rtuCount = value ? parseInt(value) : 0;

      let newRtuList = [];
      for (let i = 0; i < rtuCount; i++) {
        newRtuList = [...newRtuList, ""];
      }

      setRtuList(newRtuList);
      setFormDataProperty("rtuList", newRtuList);
    }
  };

  const handleOpenFile = (e) => {
    fileInputRef.current?.click();
  };

  const handleUpload = (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    if (file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workBook = XLSX.read(data, { type: "binary" });
        const sheetName = workBook.SheetNames[0];
        //헤더명 지정, 두번째 row부터 읽기
        const result = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName], {
          header: ["no", "rtuImei"],
          range: 2,
        });

        let newRtuList = [];
        for (let i = 0; i < result.length; i++) {
          newRtuList = [...newRtuList, result[i].rtuImei];
        }

        setRtuList(newRtuList);
        setFormDataProperty("rtuList", newRtuList);

        //테이블 Row 수 변경 시 대상 RTU 수량 변경
        const rtuCount = newRtuList.length;
        setFormDataProperty("rtuCount", rtuCount);
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      const rtuCount = parseInt(formData.rtuCount);
      if (rtuCount > 0) {
        apiWrapper(async () => {
          let requestBody = {
            writer: formData.writer,
            writeDate: formData.writeDate + " " + getTime(new Date()),
            externalApiParameterId: parseInt(formData.integrationApi),
            rtuList: formData.rtuList,
          };
          let result = await externalApiApi.createExternalApiInterlock(
            requestBody
          );
          const { status, data } = result;
          if (status === true) {
            alert("저장 성공하였습니다.");
            props.onHide();
          } else {
            alert(`저장 실패하였습니다.\n${data}`);
          }
        });
      } else {
        alert("저장할 데이터가 없습니다.");
      }
    }

    //유효성 검사 완료 처리
    setValidated(true);
  };

  return (
    <>
      {formData && (
        <Modal
          {...props}
          show
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="external-api-integration modal-30w"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              API 연동 등록
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate onSubmit={handleSave}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  연동 API 선택
                </Form.Label>
                <Col sm={9}>
                  <Form.Select
                    name="integrationApi"
                    value={formData.integrationApi}
                    onChange={onChange}
                    style={{ textAlign: "center" }}
                  >
                    <option value="0">미사용</option>
                    {parameterList &&
                      parameterList.map((item, index) => (
                        <option key={item.id} value={item.id}>
                          {item.companyName}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  등록자
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    isInvalid={validated && !formData.writer}
                    type="text"
                    placeholder=""
                    name="writer"
                    value={formData.writer}
                    onChange={onChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    등록자를 입력하세요.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  등록일시
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    isInvalid={validated && !formData.writeDate}
                    type="date"
                    placeholder=""
                    name="writeDate"
                    value={formData.writeDate}
                    onChange={onChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    등록일시를 선택하세요.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  대상 RTU 수량
                </Form.Label>
                <Col>
                  <Form.Control
                    isInvalid={validated && !formData.rtuCount}
                    type="number"
                    placeholder=""
                    name="rtuCount"
                    value={formData.rtuCount}
                    onChange={onChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    대상 RTU 수량을 입력하세요.
                  </Form.Control.Feedback>
                </Col>
                <Col>
                  <span className="d-flex">
                    <a
                      href={
                        SERVER_HOST + `/media/sample/alliothub_api_sample.xlsx`
                      }
                    >
                      <Button className="ms-2" variant="primary">
                        샘플
                      </Button>
                    </a>
                    <Button
                      className="ms-auto"
                      variant="primary"
                      onClick={handleOpenFile}
                    >
                      파일 불러오기
                    </Button>
                    <input
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      type="file"
                      accept=".xlsx"
                      onChange={handleUpload}
                    />
                  </span>
                </Col>
              </Form.Group>
              <div className="table-table mb-3">
                <Table bordered hover style={{ textAlign: "center" }}>
                  <thead className="table-header">
                    <tr>
                      <th>No</th>
                      <th>RTU IMEI</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rtuList.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Form.Control
                            name="rtuList"
                            value={formData.rtuList[index]}
                            onChange={(e) => handleChangeRtuList(e, index)}
                            style={{ textAlign: "center" }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-center">
                <Button variant="primary" type="submit" size="lg">
                  저장
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
