import { axiosInstance, parseResult } from "./CommonApi";

//RTU 등록현황
export const getDashboardMonitor = async () => {
  const response = await axiosInstance.get(`/dashboard/monitor/`);
  return parseResult(response);
};

//고장 RTU 리스트
export const getDashboardErrorRtus = async (deviceDivision) => {
  const response = await axiosInstance.get(
    `/dashboard/monitor/error/${deviceDivision}/`
  );
  return parseResult(response);
};
