import { apiWrapper } from "apis/CommonApi";
import PaginatedItems from "components/common/PaginatedItems";
import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Form,
  InputGroup,
  Col,
  Row,
  Button,
  Table,
} from "react-bootstrap";
import "./RtuRequestPage.scss";
import * as rtuRequestApi from "../apis/RtuRequestApi";
import UARTSendComponent from "components/rtu-request/UARTSendComponent";
import { convertHexToNumber, convertNumberToHex } from "utils/number";
import Loading from "components/common/Loading";

const RtuRequestColumns = {
  CHECK: "0",
  ID: "1",
  RTU_IMEI: "2",
  BUSINESS_ID: "3",
  SERVICE_ID: "4",
  DEVICE_DIVISION: "5",
  UART: "6",
  NETWORK: "7",
  POLLING_CONTROL: "8",
  QUERY: "9",
  REMS_CONTROL: "10",
  FW_VERSION: "11",
  ALL_CHECK: "12",
};

const SearchOptions = {
  RTU_IMEI: "0",
  BUSINESS_ID: "1",
  SERVICE_ID: "2",
  DEVICE_DIVISION: "3",
};

const getSearchOptionName = (value) => {
  let name = "";
  const key = Object.keys(SearchOptions)[value];
  switch (key) {
    case "RTU_IMEI":
      name = "RTU IMEI";
      break;
    case "BUSINESS_ID":
      name = "사업자 ID";
      break;
    case "SERVICE_ID":
      name = "서비스 ID";
      break;
    case "DEVICE_DIVISION":
      name = "기기모델";
      break;
    default:
      break;
  }
  return name;
};

export default function RtuRequestPage() {
  const itemsPerPage = 100;
  const searchTextRef = useRef(null);
  const [searchOption, setSearchOption] = useState(SearchOptions.RTU_IMEI);
  const [dataSource, setDataSource] = useState([]);
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const [page, setPage] = useState(1);
  const [checkIds, setCheckIds] = useState([]);
  const [checkDataSource, setCheckDataSource] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData(1);
  }, []);

  //page: 1베이스
  const getData = (page, url = null) => {
    apiWrapper(async () => {
      setIsLoading(true);
      let result;
      if (url !== null) {
        result = await rtuRequestApi.getRtuRequestsByUrl(url);
      } else {
        const { rtuImei, deviceDivision, businessId, serviceId } =
          getSearchParams();

        result = await rtuRequestApi.getRtuRequests(
          rtuImei,
          deviceDivision,
          businessId,
          serviceId,
          page,
          itemsPerPage
        );
      }
      setIsLoading(false);

      const { status, data } = result;
      if (status === true) {
        setDataSource(data);

        setPrevious(data.previous);
        setNext(data.next);
        setPage(page);

        //체크여부
        let tempDataSource = [];
        if (data.results) {
          data.results.map((item, index) => {
            tempDataSource.push({
              id: item.id,
              uart: false,
              network: false,
              pollingControl: false,
              query: false,
              remsControl: false,
              fwVersion: false,
              allCheck: false,
            });
          });

          setCheckDataSource(tempDataSource);
        }
      }
    });
  };

  const getSearchParams = () => {
    let rtuImei = null;
    let deviceDivision = null;
    let businessId = null;
    let serviceId = null;

    if (searchTextRef.current && searchTextRef.current?.value !== "") {
      const searchText = searchTextRef.current?.value;
      switch (searchOption) {
        case SearchOptions.RTU_IMEI:
          rtuImei = searchText;
          break;
        case SearchOptions.DEVICE_DIVISION:
          deviceDivision = searchText;
          break;
        case SearchOptions.BUSINESS_ID:
          businessId = convertHexToNumber(searchText);
          break;
        case SearchOptions.SERVICE_ID:
          serviceId = convertHexToNumber(searchText);
          break;
        default:
          break;
      }
    }

    return {
      rtuImei: rtuImei,
      deviceDivision: deviceDivision,
      businessId: businessId,
      serviceId: serviceId,
    };
  };

  const handlePageClick = (e) => {
    if (typeof e.nextSelectedPage !== "undefined") {
      const selectedPage = e.nextSelectedPage + 1;
      let url = null;
      if (e.isPrevious) {
        url = previous;
      } else if (e.isNext) {
        url = next;
      }

      getData(selectedPage, url);
    }
  };

  const handleChangeSearchOption = (e) => {
    setSearchOption(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getData(1);
  };

  const handleSendUART = () => {
    setModalShow(true);
  };

  const handleRequest = () => {
    if (checkIds.length > 0) {
      let requestBody = [];
      apiWrapper(async () => {
        for (let i = 0; i < checkDataSource.length; i++) {
          const item = checkDataSource[i];
          if (checkIds.includes(item.id)) {
            if (
              item.uart === false &&
              item.network === false &&
              item.pollingControl === false &&
              item.query === false &&
              item.remsControl === false &&
              item.fwVersion === false
            ) {
              alert(
                `요청할 REMS Control Order 항목을 1개 이상 선택해주세요. (ID : ${item.id})`
              );
              return;
            } else {
              requestBody.push({
                id: item.id,
                uart: item.uart,
                network: item.network,
                pollingControl: item.pollingControl,
                query: item.query,
                remsControl: item.remsControl,
                fwVersion: item.fwVersion,
              });
            }
          }
        }
        //console.log(JSON.stringify(requestBody));
        let result = await rtuRequestApi.requestRtu(requestBody);

        const { status, data } = result;
        if (status === true) {
          alert("RTU 요청 성공하였습니다.");
        } else {
          alert(`RTU 요청 실패하였습니다.\n${data}`);
        }
      });
    } else {
      alert("요청할 ID를 선택해주세요.");
    }
  };

  const handleChangeCheckAllByColumn = (e, column) => {
    const checked = e.target.checked;
    let checkIds;
    if (dataSource.results) {
      if (checked) {
        //전체 체크
        let allIds = [];
        dataSource.results.map((item, index) => {
          allIds.push(item.id);
        });
        checkIds = allIds;
      } else {
        //전체 체크 해제
        checkIds = [];
      }
    }

    switch (column) {
      case RtuRequestColumns.CHECK:
        setCheckIds(checkIds);
        break;
      case RtuRequestColumns.UART:
        setCheckDataSource(
          checkDataSource.map((item) => {
            return { ...item, uart: checked };
          })
        );
        break;
      case RtuRequestColumns.NETWORK:
        setCheckDataSource(
          checkDataSource.map((item) => {
            return { ...item, network: checked };
          })
        );
        break;
      case RtuRequestColumns.POLLING_CONTROL:
        setCheckDataSource(
          checkDataSource.map((item) => {
            return { ...item, pollingControl: checked };
          })
        );
        break;
      case RtuRequestColumns.QUERY:
        setCheckDataSource(
          checkDataSource.map((item) => {
            return { ...item, query: checked };
          })
        );
        break;
      case RtuRequestColumns.REMS_CONTROL:
        setCheckDataSource(
          checkDataSource.map((item) => {
            return { ...item, remsControl: checked };
          })
        );
        break;
      case RtuRequestColumns.FW_VERSION:
        setCheckDataSource(
          checkDataSource.map((item) => {
            return { ...item, fwVersion: checked };
          })
        );
        break;
      case RtuRequestColumns.ALL_CHECK:
        setCheckIds(checkIds);
        setCheckDataSource(
          checkDataSource.map((item) => {
            return {
              ...item,
              uart: checked,
              network: checked,
              pollingControl: checked,
              query: checked,
              remsControl: checked,
              fwVersion: checked,
              allCheck: checked,
            };
          })
        );
        break;
      default:
        break;
    }
  };

  const handleChangeCheckByColumn = (e, id, column) => {
    const checked = e.target.checked;
    switch (column) {
      case RtuRequestColumns.CHECK:
        if (e.target.checked) {
          setCheckIds([...checkIds, id]);
        } else {
          setCheckIds(checkIds.filter((a) => a !== id));
        }
        break;
      case RtuRequestColumns.UART:
        setCheckDataSource(
          checkDataSource.map((item) => {
            if (item.id === id) {
              return { ...item, uart: checked };
            } else {
              return item;
            }
          })
        );
        break;
      case RtuRequestColumns.NETWORK:
        setCheckDataSource(
          checkDataSource.map((item) => {
            if (item.id === id) {
              return { ...item, network: checked };
            } else {
              return item;
            }
          })
        );
        break;
      case RtuRequestColumns.POLLING_CONTROL:
        setCheckDataSource(
          checkDataSource.map((item) => {
            if (item.id === id) {
              return { ...item, pollingControl: checked };
            } else {
              return item;
            }
          })
        );
        break;
      case RtuRequestColumns.QUERY:
        setCheckDataSource(
          checkDataSource.map((item) => {
            if (item.id === id) {
              return { ...item, query: checked };
            } else {
              return item;
            }
          })
        );
        break;
      case RtuRequestColumns.REMS_CONTROL:
        setCheckDataSource(
          checkDataSource.map((item) => {
            if (item.id === id) {
              return { ...item, remsControl: checked };
            } else {
              return item;
            }
          })
        );
        break;
      case RtuRequestColumns.FW_VERSION:
        setCheckDataSource(
          checkDataSource.map((item) => {
            if (item.id === id) {
              return { ...item, fwVersion: checked };
            } else {
              return item;
            }
          })
        );
        break;
      case RtuRequestColumns.ALL_CHECK:
        if (e.target.checked) {
          setCheckIds([...checkIds, id]);
        } else {
          setCheckIds(checkIds.filter((a) => a !== id));
        }
        setCheckDataSource(
          checkDataSource.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                uart: checked,
                network: checked,
                pollingControl: checked,
                query: checked,
                remsControl: checked,
                fwVersion: checked,
                allCheck: checked,
              };
            } else {
              return item;
            }
          })
        );
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setModalShow(false);
  };

  return (
    <>
      {modalShow && <UARTSendComponent onHide={handleClose} />}
      <Container fluid className="mb-3 d-flex align-items-center">
        <h1>RTU 요청</h1>
      </Container>

      <Container fluid className="mb-3 d-flex align-items-center">
        <Form onSubmit={handleSearch}>
          <Row className="align-items-center">
            <Col xs={"auto"}>
              <InputGroup>
                <Form.Select
                  style={{ width: "150px" }}
                  value={searchOption}
                  onChange={handleChangeSearchOption}
                >
                  {Object.values(SearchOptions).map((item, index) => (
                    <option key={index} value={item}>
                      {getSearchOptionName(item)}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control
                  ref={searchTextRef}
                  style={{ width: "300px" }}
                  placeholder="검색할 문자열을 입력하세요."
                />
                <Button
                  variant="primary"
                  style={{ width: "130px" }}
                  type="submit"
                >
                  검색
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
        <span className="ms-auto">
          <Button className="ms-2" variant="primary" onClick={handleSendUART}>
            UART 전송
          </Button>
          <Button className="ms-2" variant="primary" onClick={handleRequest}>
            요청
          </Button>
        </span>
      </Container>

      {isLoading ? (
        <Loading />
      ) : (
        <div className="rtu-req-table">
          <div className="table-table">
            <Table
              bordered
              hover
              style={{ overflowX: "auto", whiteSpace: "nowrap" }}
            >
              <thead className="table-header">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      style={{ zoom: 1.0 }}
                      className="form-check-input"
                      onChange={(e) =>
                        handleChangeCheckAllByColumn(e, RtuRequestColumns.CHECK)
                      }
                    />
                  </th>
                  <th>ID</th>
                  <th>RTU IMEI</th>
                  <th>사업자 ID</th>
                  <th>서비스 ID</th>
                  <th>기기모델</th>
                  <th>
                    <input
                      type="checkbox"
                      style={{ zoom: 1.0 }}
                      className="form-check-input"
                      onChange={(e) =>
                        handleChangeCheckAllByColumn(e, RtuRequestColumns.UART)
                      }
                    />
                    <div>
                      <label>UART</label>
                    </div>
                  </th>
                  <th>
                    <input
                      type="checkbox"
                      style={{ zoom: 1.0 }}
                      className="form-check-input"
                      onChange={(e) =>
                        handleChangeCheckAllByColumn(
                          e,
                          RtuRequestColumns.NETWORK
                        )
                      }
                    />
                    <div>
                      <label>NETWORK</label>
                    </div>
                  </th>
                  <th>
                    <input
                      type="checkbox"
                      style={{ zoom: 1.0 }}
                      className="form-check-input"
                      onChange={(e) =>
                        handleChangeCheckAllByColumn(
                          e,
                          RtuRequestColumns.POLLING_CONTROL
                        )
                      }
                    />
                    <div>
                      <label>POLLING CONTROL</label>
                    </div>
                  </th>
                  <th>
                    <input
                      type="checkbox"
                      style={{ zoom: 1.0 }}
                      className="form-check-input"
                      onChange={(e) =>
                        handleChangeCheckAllByColumn(e, RtuRequestColumns.QUERY)
                      }
                    />
                    <div>
                      <label>QUERY</label>
                    </div>
                  </th>
                  <th>
                    <input
                      type="checkbox"
                      style={{ zoom: 1.0 }}
                      className="form-check-input"
                      onChange={(e) =>
                        handleChangeCheckAllByColumn(
                          e,
                          RtuRequestColumns.REMS_CONTROL
                        )
                      }
                    />
                    <div>
                      <label>REMS CONTROL</label>
                    </div>
                  </th>
                  <th>
                    <input
                      type="checkbox"
                      style={{ zoom: 1.0 }}
                      className="form-check-input"
                      onChange={(e) =>
                        handleChangeCheckAllByColumn(
                          e,
                          RtuRequestColumns.FW_VERSION
                        )
                      }
                    />
                    <div>
                      <label>FW VERSION</label>
                    </div>
                  </th>
                  <th>
                    <input
                      type="checkbox"
                      style={{ zoom: 1.0 }}
                      className="form-check-input"
                      onChange={(e) =>
                        handleChangeCheckAllByColumn(
                          e,
                          RtuRequestColumns.ALL_CHECK
                        )
                      }
                    />
                    <div>
                      <label>ALL CHECK</label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="table-body">
                {dataSource.results &&
                  dataSource.results.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          style={{ zoom: 1.0 }}
                          className="form-check-input"
                          onChange={(e) =>
                            handleChangeCheckByColumn(
                              e,
                              item.id,
                              RtuRequestColumns.CHECK
                            )
                          }
                          checked={checkIds.includes(item.id)}
                        />
                      </td>
                      <td>{item.id}</td>
                      <td>{item.rtuImei}</td>
                      <td>{convertNumberToHex(parseInt(item.businessId))}</td>
                      <td>{convertNumberToHex(parseInt(item.serviceId))}</td>
                      <td>{item.deviceDivision}</td>

                      <td>
                        <input
                          type="checkbox"
                          style={{ zoom: 1.0 }}
                          className="form-check-input"
                          onChange={(e) =>
                            handleChangeCheckByColumn(
                              e,
                              item.id,
                              RtuRequestColumns.UART
                            )
                          }
                          checked={checkDataSource[index].uart}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          style={{ zoom: 1.0 }}
                          className="form-check-input"
                          onChange={(e) =>
                            handleChangeCheckByColumn(
                              e,
                              item.id,
                              RtuRequestColumns.NETWORK
                            )
                          }
                          checked={checkDataSource[index].network}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          style={{ zoom: 1.0 }}
                          className="form-check-input"
                          onChange={(e) =>
                            handleChangeCheckByColumn(
                              e,
                              item.id,
                              RtuRequestColumns.POLLING_CONTROL
                            )
                          }
                          checked={checkDataSource[index].pollingControl}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          style={{ zoom: 1.0 }}
                          className="form-check-input"
                          onChange={(e) =>
                            handleChangeCheckByColumn(
                              e,
                              item.id,
                              RtuRequestColumns.QUERY
                            )
                          }
                          checked={checkDataSource[index].query}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          style={{ zoom: 1.0 }}
                          className="form-check-input"
                          onChange={(e) =>
                            handleChangeCheckByColumn(
                              e,
                              item.id,
                              RtuRequestColumns.REMS_CONTROL
                            )
                          }
                          checked={checkDataSource[index].remsControl}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          style={{ zoom: 1.0 }}
                          className="form-check-input"
                          onChange={(e) =>
                            handleChangeCheckByColumn(
                              e,
                              item.id,
                              RtuRequestColumns.FW_VERSION
                            )
                          }
                          checked={checkDataSource[index].fwVersion}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          style={{ zoom: 1.0 }}
                          className="form-check-input"
                          onChange={(e) =>
                            handleChangeCheckByColumn(
                              e,
                              item.id,
                              RtuRequestColumns.ALL_CHECK
                            )
                          }
                          checked={checkDataSource[index].allCheck}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div style={{ marginTop: 20 }}>
            <PaginatedItems
              totalItemsCount={dataSource.count}
              itemsPerPage={itemsPerPage}
              selectedPage={page - 1}
              onClick={handlePageClick}
            />
          </div>
        </div>
      )}
    </>
  );
}
