import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";

export default function Loading() {
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="border"
            size="lm"
            role="status"
            aria-hidden="true"
          />
        </Button>
      </div>
    </>
  );
}
