import { apiWrapper } from "apis/CommonApi";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getMultiIdName, MultiIdChoice } from "utils/common";
import "./RemsInterlockAddDetailPage.scss";
import * as remsInterlockAddApi from "../apis/RemsInterlockAddApi";
import Select from "react-select";

export default function RemsInterlockAddDetailPage(props) {
  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [rtuImeis, setRtuImeis] = useState([]);
  const [selectedRtuImei, setSelectedRtuImei] = useState(null);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    setFormDataProperty("cid", "");
    setFormDataProperty("authKey", "");
    setFormDataProperty("worker", "");
    setFormDataProperty("phoneNumber", "");
    setFormDataProperty("address", "");
    setFormDataProperty("facCompany", "");
    setFormDataProperty("monitorCompany", "");
    setFormDataProperty("token", null);
    setFormDataProperty("multiId", "");

    apiWrapper(async () => {
      if (id) {
        const result = await remsInterlockAddApi.getRemsInterlock(id);
        const { status, data } = result;
        if (status === true) {
          setSelectedRtuImei({ value: data.rtuId, label: data.rtuImei });
          setFormDataProperty("cid", data.cid);
          setFormDataProperty("authKey", data.authKey);
          setFormDataProperty("worker", data.worker);
          setFormDataProperty("phoneNumber", data.phoneNumber);
          setFormDataProperty("address", data.address);
          setFormDataProperty("facCompany", data.facCompany);
          setFormDataProperty("monitorCompany", data.monitorCompany);
          setFormDataProperty("token", data.token);
          setFormDataProperty("multiId", data.multiId);
        }
      } else {
        //RTU IMEI 설정
        let rtuImeis = await remsInterlockAddApi.getRtuImeis();
        const { status, data } = rtuImeis;
        if (status === true) {
          setRtuImeis(data);
        }
      }
    });

    const state = {};
    const title = "";
    let url = "/rems-interlock-add/detail/";
    if (id) {
      url = `/rems-interlock-add/detail/${id}`;
    }
    window.history.pushState(state, title, url);
    window.onpopstate = handleBack;
    return () => {
      window.onpopstate = null;
    };
  }, []);

  const handleBack = () => {
    navigate(location.state?.prevUrl, {
      state: { condition: location.state?.condition },
    });
  };

  const setFormDataProperty = (name, value) => {
    setFormData((currentFormData) => {
      const nextFormData = {
        ...currentFormData,
        [name]: value,
      };
      //console.log(nextFormData);
      return nextFormData;
    });
  };

  const onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setFormDataProperty(name, value);
  };

  const handleSave = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      let requestBody = {
        cid: formData.cid,
        authKey: formData.authKey,
        worker: formData.worker,
        phoneNumber: formData.phoneNumber,
        address: formData.address,
        facCompany: formData.facCompany,
        monitorCompany: formData.monitorCompany,
        multiId: parseInt(formData.multiId),
        token: formData.token,
        rtuId: parseInt(selectedRtuImei.value),
      };
      //console.log(JSON.stringify(requestBody));

      apiWrapper(async () => {
        let result;
        if (id) {
          result = await remsInterlockAddApi.updateRemsInterlock(
            id,
            requestBody
          );
        } else {
          result = await remsInterlockAddApi.createRemsInterlock(requestBody);
        }

        const { status, data } = result;
        if (status === true) {
          alert("저장 완료하였습니다.");
          handleBack();
        } else {
          alert(`저장 실패하였습니다.\n${data}`);
        }
      });
    }
  };

  const handleDelete = (e) => {
    if (window.confirm("삭제하시겠습니까?")) {
      apiWrapper(async () => {
        const result = await remsInterlockAddApi.deleteRemsInterlock(id);

        const { status, data } = result;
        if (status === true) {
          alert("삭제되었습니다.");
          handleBack();
        } else {
          alert(`삭제 실패하였습니다.\n${data}`);
        }
      });
    }
  };

  const getRtuImeis = () => {
    let list = [];

    if (rtuImeis) {
      rtuImeis.map((item) => {
        list.push({ value: item.id, label: item.rtuImei });
      });
    }

    return list;
  };

  const handleChangeRtuImei = (e) => {
    setSelectedRtuImei(e);
  };

  return (
    <>
      <Container fluid className="mb-3 d-flex align-items-center">
        <h1>REMS 연동 정보</h1>
        <span className="ms-auto">
          <Button type="submit" form="form" className="ms-2" variant="primary">
            저장
          </Button>
          {id && (
            <Button
              form="form"
              className="ms-2"
              variant="primary"
              onClick={handleDelete}
            >
              삭제
            </Button>
          )}
        </span>
      </Container>

      {formData && (
        <div className="rtu-set-detail">
          <div className="contents-wrap">
            <Form id="form" onSubmit={handleSave}>
              <div className="flex-wrap">
                <div className="left-wrap">
                  <div className="group-box">
                    <Form.Group as={Row}>
                      <Form.Label column>CID(*)</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="cid"
                          minLength={11}
                          maxLength={11}
                          value={formData.cid}
                          onChange={onChange}
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>인증키(*)</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="authKey"
                          value={formData.authKey}
                          onChange={onChange}
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>설치자(*)</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="worker"
                          value={formData.worker}
                          onChange={onChange}
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>설치자 연락처</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={onChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>주소</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="address"
                          value={formData.address}
                          onChange={onChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>설비회사</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="facCompany"
                          value={formData.facCompany}
                          onChange={onChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>모니터링업체</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="monitorCompany"
                          value={formData.monitorCompany}
                          onChange={onChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>RTU Registration(*)</Form.Label>
                      <Col>
                        <Select
                          options={getRtuImeis()}
                          name="rtuId"
                          placeholder=""
                          value={selectedRtuImei}
                          onChange={handleChangeRtuImei}
                          isDisabled={id}
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>Multi ID(*)</Form.Label>
                      <Col>
                        <Form.Select
                          name="multiId"
                          value={formData.multiId}
                          onChange={onChange}
                          required
                        >
                          <option value="">-</option>
                          {Object.values(MultiIdChoice).map((item, index) => (
                            <option key={index} value={item}>
                              {getMultiIdName(item)}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column>토큰</Form.Label>
                      <Col>
                        <Form.Label name="token">
                          {formData.token !== null ? formData.token : "-"}
                        </Form.Label>
                      </Col>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
}
