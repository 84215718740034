import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
  menuClasses,
  MenuItemStyles,
} from "react-pro-sidebar";
import { GrLogout } from "react-icons/gr";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdMenu,
  MdOutlineManageSearch,
} from "react-icons/md";
import { AiOutlineSetting, AiOutlineFolderAdd } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import { IoLogoBuffer } from "react-icons/io";
import "./Sidebar.scss";
import { deleteToken, useAppContext } from "utils/store";
import AuthorityCheckRoute from "utils/AuthorityCheckRoute";
import { UserAuthorityChoice } from "utils/common";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "#fbfcfd",
      // icon: "#0098e5",
      icon: "#000000",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export default function MySidebar() {
  const navigate = useNavigate();
  const { store, dispatch } = useAppContext();
  const [theme, setTheme] = React.useState("light");
  const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();

  const [hasImage, setHasImage] = React.useState(false);

  const menuItemStyles = {
    root: {
      fontSize: "16px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const onLogout = (e) => {
    if (window.confirm("로그아웃하시겠습니까?")) {
      dispatch(deleteToken());
      navigate("/");
    }
  };

  const handleExpandCollapse = (e) => {
    collapseSidebar();
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Sidebar
        customBreakPoint={"none"}
        backgroundColor={hexToRgba(
          themes[theme].sidebar.backgroundColor,
          hasImage ? 0.9 : 1
        )}
        rootStyles={{
          color: themes[theme].sidebar.color,
        }}
        collapsed={collapsed}
        collapsedWidth="85px"
      >
        <Menu
          menuItemStyles={menuItemStyles}
          rootStyles={{
            ["." + menuClasses.button]: {
              paddingLeft: "19px",
            },
          }}
        >
          <MenuItem
            className="collapsedMenu"
            icon={
              collapsed ? (
                <MdKeyboardDoubleArrowRight />
              ) : (
                <MdKeyboardDoubleArrowLeft />
              )
            }
            onClick={handleExpandCollapse}
          ></MenuItem>

          <AuthorityCheckRoute
            authority={[
              UserAuthorityChoice.ADMIN,
              UserAuthorityChoice.REMS_AND_API,
              UserAuthorityChoice.REMS,
              UserAuthorityChoice.API,
            ]}
          >
            <SubMenu label="RTU" defaultOpen={true}>
              <MenuItem
                icon={<MdOutlineManageSearch></MdOutlineManageSearch>}
                component={<Link to={"/rtu-manage/"} />}
              >
                RTU 관리
              </MenuItem>
              <MenuItem
                icon={<AiOutlineSetting></AiOutlineSetting>}
                component={<Link to={"/rtu-setting/"} />}
              >
                RTU 설정
              </MenuItem>
              <MenuItem
                icon={<FiSend></FiSend>}
                component={<Link to={"/rtu-request/"} />}
              >
                RTU 요청
              </MenuItem>
              <MenuItem
                icon={<IoLogoBuffer></IoLogoBuffer>}
                component={<Link to={"/rtu-receive-log/"} />}
              >
                RTU 수신 로그
              </MenuItem>
              <MenuItem
                icon={<IoLogoBuffer></IoLogoBuffer>}
                component={<Link to={"/rtu-send-log/"} />}
              >
                RTU 전송 로그
              </MenuItem>
            </SubMenu>
          </AuthorityCheckRoute>
          <div style={{ marginTop: "19px" }}></div>
          <AuthorityCheckRoute
            authority={[
              UserAuthorityChoice.ADMIN,
              UserAuthorityChoice.REMS_AND_API,
              UserAuthorityChoice.REMS,
            ]}
          >
            <SubMenu label="REMS" defaultOpen={true}>
              <MenuItem
                icon={<AiOutlineFolderAdd></AiOutlineFolderAdd>}
                component={<Link to={"/rems-interlock-add/"} />}
              >
                REMS 연동 등록
              </MenuItem>
              <MenuItem
                icon={<IoLogoBuffer></IoLogoBuffer>}
                component={<Link to={"/rems-send-log/"} />}
              >
                REMS 전송 로그
              </MenuItem>
            </SubMenu>
          </AuthorityCheckRoute>
          <div style={{ marginTop: "19px" }}></div>
          <AuthorityCheckRoute
            authority={[
              UserAuthorityChoice.ADMIN,
              UserAuthorityChoice.REMS_AND_API,
              UserAuthorityChoice.API,
            ]}
          >
            <SubMenu
              label={collapsed ? "API" : "외부 전송 API"}
              defaultOpen={true}
            >
              <MenuItem
                icon={<AiOutlineFolderAdd></AiOutlineFolderAdd>}
                component={<Link to={"/exapi/"} />}
              >
                외부 전송 API 관리
              </MenuItem>
              <MenuItem
                icon={<IoLogoBuffer></IoLogoBuffer>}
                component={<Link to={"/exapi-send-log/"} />}
              >
                외부 전송 로그
              </MenuItem>
            </SubMenu>
          </AuthorityCheckRoute>
          <div style={{ marginTop: "19px" }}></div>
          <MenuItem
            icon={<GrLogout></GrLogout>}
            style={{ marginTop: 23 }}
            onClick={onLogout}
          >
            로그아웃
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
}
