export const UseChoice = {
  NO_USE: "0",
  USE: "1",
};

export const getUseName = (value) => {
  let name = "";
  const key = Object.keys(UseChoice)[value];
  switch (key) {
    case "NO_USE":
      name = "미사용";
      break;
    case "USE":
      name = "사용";
      break;
    default:
      break;
  }

  return name;
};

export const SendSuccessFailChoice = {
  FAIL: "0",
  SUCCESS: "1",
};

export const getSendSuccessFailName = (value) => {
  let name = "";
  const key = Object.keys(SendSuccessFailChoice)[value];
  switch (key) {
    case "FAIL":
      name = "전송 실패";
      break;
    case "SUCCESS":
      name = "전송 성공";
      break;
    default:
      break;
  }

  return name;
};

export const RtuOperationModeChoice = {
  REMS_MODE: "0",
  POLLING_MODE: "1",
  EVENT_MODE: "2",
};

export const getRtuOperationModeName = (value) => {
  let name = "";
  const key = Object.keys(RtuOperationModeChoice)[value];
  switch (key) {
    case "REMS_MODE":
      name = "REMS 모드";
      break;
    case "POLLING_MODE":
      name = "Polling 모드";
      break;
    case "EVENT_MODE":
      name = "이벤트 모드";
      break;
    default:
      break;
  }

  return name;
};

export const UartInterfaceChoice = {
  RS485: "0",
  RS232: "1",
  I2C: "2",
};

export const getUartInterfaceName = (value) => {
  let name = "";
  const key = Object.keys(UartInterfaceChoice)[value];
  switch (key) {
    case "RS485":
      name = "RS485";
      break;
    case "RS232":
      name = "RS232";
      break;
    case "I2C":
      name = "I2C";
      break;
    default:
      break;
  }

  return name;
};

export const UartBaudRateChoice = {
  _9600: "0",
  _19200: "1",
  _38600: "2",
  _57600: "3",
  _115200: "4",
};

export const getUartBaudRateName = (value) => {
  let name = "";
  const key = Object.keys(UartBaudRateChoice)[value];
  switch (key) {
    case "_9600":
      name = "9600";
      break;
    case "_19200":
      name = "19200";
      break;
    case "_38600":
      name = "38600";
      break;
    case "_57600":
      name = "57600";
      break;
    case "_115200":
      name = "115200";
      break;
    default:
      break;
  }

  return name;
};

export const EnergyTypeChoice = {
  SOLAR_1: "0",
  SOLAR_3: "1",
  SOLAR_FORCE: "2",
  SOLAR_NATURE: "3",
  GEO_HIT: "4",
  GEO_LOAD: "5",
  WINDFORCE: "6",
  NA: "7",
  FUEL_CELL: "8",
  ESS: "9",
};

export const getEnergyTypeName = (value) => {
  let name = "";
  const key = Object.keys(EnergyTypeChoice)[value];
  switch (key) {
    case "SOLAR_1":
      name = "태양광 단상";
      break;
    case "SOLAR_3":
      name = "태양광 삼상";
      break;
    case "SOLAR_FORCE":
      name = "태양열 강제순환식";
      break;
    case "SOLAR_NATURE":
      name = "태양열 자연순환식";
      break;
    case "GEO_HIT":
      name = "지열 히트펌프측";
      break;
    case "GEO_LOAD":
      name = "지열 부하측";
      break;
    case "WINDFORCE":
      name = "풍력";
      break;
    case "NA":
      name = "NA";
      break;
    case "FUEL_CELL":
      name = "연료전지";
      break;
    case "ESS":
      name = "ESS";
      break;
    default:
      break;
  }

  return name;
};

export const RtuAckTypeChoice = {
  ONCE: "0",
  CONTINUE: "1",
};

export const getRtuAckTypeName = (value) => {
  let name = "";
  const key = Object.keys(RtuAckTypeChoice)[value];
  switch (key) {
    case "ONCE":
      name = "1회 ACK";
      break;
    case "CONTINUE":
      name = "지속 ACK";
      break;
    default:
      break;
  }

  return name;
};

export const MultiIdChoice = {
  MULTI_1: 1,
  MULTI_2: 2,
  MULTI_3: 3,
  MULTI_4: 4,
};

export const getMultiIdName = (value) => {
  let name = "";
  const index = Object.values(MultiIdChoice).indexOf(value);
  const key = Object.keys(MultiIdChoice)[index];
  switch (key) {
    case "MULTI_1":
      name = "설비 1";
      break;
    case "MULTI_2":
      name = "설비 2";
      break;
    case "MULTI_3":
      name = "설비 3";
      break;
    case "MULTI_4":
      name = "설비 4";
      break;
    default:
      break;
  }

  return name;
};

export const RtuMsgTypeChoice = {
  UL_DATA: "0",
  BOOT_OR_HEALTH: "1",
  STATUS_REQUEST_ACK: "2",
  UL_DATA_ACK: "3",
  HEALTH_CHECK_ACK: "4",
  STATUS_REQUEST: "5",
  DL_DATA_CONTROL_REQUEST: "6",
  FIRM_DL_DATA_CONTROL_REQUEST: "7",
  OPER_DL_CONTROL_REQUEST: "8",
};

export const getRtuMsgTypeName = (value) => {
  let name = "";
  const key = Object.keys(RtuMsgTypeChoice)[value];
  switch (key) {
    case "UL_DATA":
      name = "UL 데이터 전송";
      break;
    case "BOOT_OR_HEALTH":
      name = "Boot or Health 전송";
      break;
    case "STATUS_REQUEST_ACK":
      name = "Status 요청 응답";
      break;
    case "UL_DATA_ACK":
      name = "UL 데이터 수신 Ack";
      break;
    case "HEALTH_CHECK_ACK":
      name = "Health 체크 수신 Ack";
      break;
    case "STATUS_REQUEST":
      name = "Status 요청";
      break;
    case "DL_DATA_CONTROL_REQUEST":
      name = "DL 데이터 제어 요청";
      break;
    case "FIRM_DL_DATA_CONTROL_REQUEST":
      name = "펌웨어 DL 제어 요청";
      break;
    case "OPER_DL_CONTROL_REQUEST":
      name = "동작설정 DL 제어 요청";
      break;
    default:
      break;
  }

  return name;
};

export const DataTypeChoice = {
  ASCII: "0",
  HEX: "1",
};

export const getDataTypeName = (value) => {
  let name = "";
  const key = Object.keys(DataTypeChoice)[value];
  switch (key) {
    case "ASCII":
      name = "ASCII (250자 이내)";
      break;
    case "HEX":
      name = "HEX (500자 이내)";
      break;
    default:
      break;
  }

  return name;
};

export const UserAuthorityChoice = {
  ADMIN: "0",
  REMS_AND_API: "1",
  REMS: "2",
  API: "3",
};

export const getUserAuthorityChoice = (value) => {
  let name = "";
  const key = Object.keys(RtuMsgTypeChoice)[value];
  switch (key) {
    case "ADMIN":
      name = "시스템 관리자";
      break;
    case "REMS_AND_API":
      name = "REMS+API 사용자";
      break;
    case "REMS":
      name = "REMS 사용자";
      break;
    case "API":
      name = "API 사용자";
      break;
    default:
      break;
  }

  return name;
};
