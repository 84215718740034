import { axiosInstance, parseResult } from "./CommonApi";

//외부 API 전송 로그 리스트 조회
export const getExternalApiSendLogs = async (rtuImei, page, pageSize) => {
  const params = {
    rtu_imei: rtuImei,
    page: page,
    page_size: pageSize,
  };
  const response = await axiosInstance.get(`/exapi/send-log/`, {
    params,
  });
  return parseResult(response);
};

export const getExternalApiSendLogsByUrl = async (url) => {
  const response = await axiosInstance.get(url);
  return parseResult(response);
};

//외부 API 전송 로그 리스트 엑셀 저장
export const exportExcelExternalApiSendLogs = async (rtuImei) => {
  const params = { rtu_imei: rtuImei };
  const response = await axiosInstance.post(
    `/exapi/send-log/export/`,
    {},
    { params, responseType: "blob" }
  );

  const result = parseResult(response);
  const { status, data } = result;
  if (status === true) {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers["content-type"] })
    );
    const filename =
      response.headers["content-disposition"]?.split("filename=")[1];

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return result;
};
