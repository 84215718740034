import { axiosInstance, parseResult } from "./CommonApi";

//RTU 관리 리스트 조회
export const getRtuManages = async (
  statusType,
  searchType,
  searchText,
  page,
  pageSize
) => {
  const params = {
    status_type: statusType,
    search_type: searchType,
    search_text: searchText,
    page: page,
    page_size: pageSize,
  };
  const response = await axiosInstance.get(`/rtu/manage/`, {
    params,
  });
  return parseResult(response);
};

export const getRtuManagesByUrl = async (url) => {
  const response = await axiosInstance.get(url);
  return parseResult(response);
};

//RTU 관리 리스트 엑셀 저장
export const exportExcelRtuManages = async (
  statusType,
  searchType,
  searchText
) => {
  const params = {
    status_type: statusType,
    search_type: searchType,
    search_text: searchText,
  };
  const response = await axiosInstance.post(
    `/rtu/manage/export/`,
    {},
    { params, responseType: "blob" }
  );

  const result = parseResult(response);
  const { status, data } = result;
  if (status === true) {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers["content-type"] })
    );
    const filename =
      response.headers["content-disposition"]?.split("filename=")[1];

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return result;
};

//RTU 상태 수정
export const updateRtuStatus = async (requestBody) => {
  const response = await axiosInstance.put(
    `/rtu/manage/list/update/`,
    requestBody
  );
  return parseResult(response);
};

//RTU 삭제
export const deleteRtus = async (requestBody) => {
  const response = await axiosInstance.put(
    `/rtu/manage/list/delete/`,
    requestBody
  );
  return parseResult(response);
};
