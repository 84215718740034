import React from "react";
import "./AppLayout.scss";

export default function AppLayout({ header, sidebar, children }) {
  return (
    <div className="app">
      <div className="header">{header}</div>
      <div className="body">
        <div className="sidebar">{sidebar}</div>
        <div className="contents">{children}</div>
      </div>
    </div>
  );
}
