import React, { createContext, useContext } from "react";
import { getLocalStorageItem, setLocalStorageItem } from "./useLocalStorage";
import useReducerWithSideEffects, {
  UpdateWithSideEffect,
} from "use-reducer-with-side-effects";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "./useSessionStorage";

const AppContext = createContext();

const reducer = (prevState, action) => {
  const { type } = action;
  if (type === SET_TOKEN_LOCAL) {
    const {
      payload: { access, refresh, userId, completedCallback },
    } = action;

    return UpdateWithSideEffect(
      {
        ...prevState,
        access,
        refresh,
        userId,
        completedCallback,
      },
      (state, dispatch) => {
        setLocalStorageItem("access", access);
        setLocalStorageItem("refresh", refresh);
        setLocalStorageItem("userId", userId);
        completedCallback();
      }
    );
  } else if (type === SET_TOKEN_SESSION) {
    const {
      payload: { access, refresh, userId, completedCallback },
    } = action;

    return UpdateWithSideEffect(
      {
        ...prevState,
        access,
        refresh,
        userId,
        completedCallback,
      },
      (state, dispatch) => {
        setSessionStorageItem("access", access);
        setSessionStorageItem("refresh", refresh);
        setSessionStorageItem("userId", userId);
        completedCallback();
      }
    );
  } else if (type === SET_AUTHORITY_LOCAL) {
    const {
      payload: { userAuthority },
    } = action;

    return UpdateWithSideEffect(
      {
        ...prevState,
        userAuthority,
      },
      (state, dispatch) => {
        setLocalStorageItem("userAuthority", userAuthority);
      }
    );
  } else if (type === SET_AUTHORITY_SESSION) {
    const {
      payload: { userAuthority },
    } = action;

    return UpdateWithSideEffect(
      {
        ...prevState,
        userAuthority,
      },
      (state, dispatch) => {
        setSessionStorageItem("userAuthority", userAuthority);
      }
    );
  } else if (type === DELETE_TOKEN) {
    return UpdateWithSideEffect(
      {
        ...prevState,
        access: "",
        refresh: "",
        userId: "",
        userAuthority: "",
      },
      (state, dispatch) => {
        setLocalStorageItem("access", "");
        setLocalStorageItem("refresh", "");
        setLocalStorageItem("userId", "");
        setLocalStorageItem("userAuthority", "");

        setSessionStorageItem("access", "");
        setSessionStorageItem("refresh", "");
        setSessionStorageItem("userId", "");
        setSessionStorageItem("userAuthority", "");
      }
    );
  } else if (type === SET_CURRENT_URL) {
    const { payload: currentUrl } = action;
    return UpdateWithSideEffect(
      {
        ...prevState,
        currentUrl,
      },
      (state, dispatch) => {
        setLocalStorageItem("currentUrl", currentUrl);
      }
    );
  }

  return prevState;
};

export const AppProvider = ({ children }) => {
  let defaultaccess = getSessionStorageItem("access", "");
  let defaultrefresh = getSessionStorageItem("refresh", "");
  let defaultUserId = getSessionStorageItem("userId", "");
  let defaultUserAuthority = getSessionStorageItem("userAuthority", "");
  if (!defaultaccess) {
    defaultaccess = getLocalStorageItem("access", "");
    defaultrefresh = getLocalStorageItem("refresh", "");
    defaultUserId = getLocalStorageItem("userId", "");
    defaultUserAuthority = getLocalStorageItem("userAuthority", "");
  }
  const [store, dispatch] = useReducerWithSideEffects(reducer, {
    access: defaultaccess,
    refresh: defaultrefresh,
    isAuthenticated: defaultaccess.length > 0,
    userId: defaultUserId,
    userAuthority: defaultUserAuthority,
    currentUrl: "",
  });

  return (
    <AppContext.Provider value={{ store, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

//Actions
const SET_TOKEN_LOCAL = "APP/SET_TOKEN";
const SET_TOKEN_SESSION = "APP/SET_TOKEN_SESSION";
const SET_AUTHORITY_LOCAL = "APP/SET_AUTHORITY";
const SET_AUTHORITY_SESSION = "APP/SET_AUTHORITY_SESSION";
const DELETE_TOKEN = "APP/DELETE_TOKEN";
const SET_CURRENT_URL = "APP/SET_CURRENT_URL";

//Action Creators
export const setTokenInLocal = (
  access,
  refresh,
  userId,
  completedCallback
) => ({
  type: SET_TOKEN_LOCAL,
  payload: {
    access,
    refresh,
    userId,
    completedCallback,
  },
});
export const setTokenInSession = (
  access,
  refresh,
  userId,
  completedCallback
) => ({
  type: SET_TOKEN_SESSION,
  payload: {
    access,
    refresh,
    userId,
    completedCallback,
  },
});
export const setAuthorityInLocal = (userAuthority) => ({
  type: SET_AUTHORITY_LOCAL,
  payload: {
    userAuthority,
  },
});
export const setAuthorityInSession = (userAuthority) => ({
  type: SET_AUTHORITY_SESSION,
  payload: {
    userAuthority,
  },
});
export const deleteToken = () => ({ type: DELETE_TOKEN });
export const setCurrentURL = (currentUrl) => ({
  type: SET_CURRENT_URL,
  payload: currentUrl,
});
