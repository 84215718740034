import { axiosInstance, parseResult } from "./CommonApi";

//RTU IMEI REMS 조회
export const getRtuImeis = async () => {
  const response = await axiosInstance.get(`/rtu/rtu-imei/rems/`);
  return parseResult(response);
};

//REMS 연동 생성
export const createRemsInterlock = async (requestBody) => {
  const response = await axiosInstance.post(`/rems/rems/`, requestBody);
  return parseResult(response);
};

//REMS 리스트 조회
export const getRemsInterlocks = async (rtuImei, page, pageSize) => {
  const params = { rtu_imei: rtuImei, page: page, page_size: pageSize };
  const response = await axiosInstance.get(`/rems/rems/`, {
    params,
  });
  return parseResult(response);
};

export const getRemsInterlocksByUrl = async (url) => {
  const response = await axiosInstance.get(url);
  return parseResult(response);
};

//REMS 연동 조회
export const getRemsInterlock = async (id) => {
  const response = await axiosInstance.get(`/rems/rems/${id}/`);
  return parseResult(response);
};

//REMS 연동 수정
export const updateRemsInterlock = async (id, requestBody) => {
  const response = await axiosInstance.put(`/rems/rems/${id}/`, requestBody);
  return parseResult(response);
};

//REMS 연동 삭제
export const deleteRemsInterlock = async (id) => {
  const response = await axiosInstance.delete(`/rems/rems/${id}/`);
  return parseResult(response);
};

//REMS 연동 삭제
export const deleteRemsInterlocks = async (requestBody) => {
  const response = await axiosInstance.delete(`/rems/rems/list/delete/`, {
    data: requestBody,
  });
  return parseResult(response);
};

//REMS 연동 리스트 엑셀 저장
export const exportExcelRemsInterlocks = async (rtuImei) => {
  const params = { rtu_imei: rtuImei };
  const response = await axiosInstance.post(
    `/rems/rems/list/export/`,
    {},
    { params, responseType: "blob" }
  );

  const result = parseResult(response);
  const { status, data } = result;
  if (status === true) {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers["content-type"] })
    );
    const filename =
      response.headers["content-disposition"]?.split("filename=")[1];

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return result;
};

//REMS 연동 리스트 엑셀 업로드
export const importExcel = async (requestBody) => {
  const response = await axiosInstance.post(
    `/rems/rems/list/import/`,
    requestBody,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return parseResult(response);
};
