import Axios from "axios";
import { API_HOST } from "../Constants";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../utils/useLocalStorage";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../utils/useSessionStorage";

export const axiosInstance = Axios.create({
  baseURL: API_HOST,
  validateStatus: false,
});

axiosInstance.interceptors.request.use(function (config) {
  let token = getSessionStorageItem("access", "");
  if (!token) {
    token = getLocalStorageItem("access", "");
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = "";
  }
  return config;
});

export const parseResult = (response) => {
  const { status, data, statusText } = response;
  if (status === 401) {
    let token = getSessionStorageItem("access", "");
    if (!token) {
      token = getLocalStorageItem("access", "");
    }

    if (token) {
      //토큰이 있는 경우, 로그아웃 처리(로그인 페이지로 이동)
      const { detail, code } = data;
      if (code === "LOGOUT") {
        alert(detail);
      }
      logout();
    } else {
      //토큰이 없는 경우, 에러 반환
      const { detail } = data;
      return { status: false, data: detail };
    }
  } else if (status >= 400) {
    //에러
    const { msg, detail } = data;
    let errorMessage = "";
    if (msg) {
      //msg 있으면 msg 리턴
      errorMessage = msg;
    } else if (detail) {
      //detail 있으면 detail 첫번째 항목 msg 리턴
      errorMessage = detail[0].msg;
    } else {
      //msg, detail 모두 없으면 에러코드+메세지 리턴
      errorMessage = `${status} ${statusText}`;
    }
    return { status: false, data: errorMessage };
  } else if (status >= 200) {
    //정상
    return { status: true, data: data };
  }
};

export const apiWrapper = async (fn) => {
  try {
    await fn();
    return true;
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
    } else {
      console.log(error);
    }
  }
  return false;
};

const logout = () => {
  setSessionStorageItem("access", "");
  setSessionStorageItem("refresh", "");
  setSessionStorageItem("userId", "");

  setLocalStorageItem("access", "");
  setLocalStorageItem("refresh", "");
  setLocalStorageItem("userId", "");
  window.location.reload();
};
