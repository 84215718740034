import React from "react";
import { useAppContext } from "./store";

export default function AuthorityCheckRoute(props) {
  const { authority, children } = props;
  const {
    store: { userAuthority },
  } = useAppContext();
  const isAuthenticated =
    userAuthority !== "" && authority.includes(userAuthority);

  if (isAuthenticated) {
    return children;
  } else {
    return null;
  }
}
