import { apiWrapper } from "../apis/CommonApi";
import { getUser, login } from "../apis/LoginApi";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  setAuthorityInLocal,
  setAuthorityInSession,
  setTokenInLocal,
  setTokenInSession,
  useAppContext,
} from "../utils/store";

import BackgroundImage from "../assets/login_background.jpg";

export default function LoginPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const { store, dispatch } = useAppContext();

  useEffect(() => {
    setFormDataProperty("userId", "");
    setFormDataProperty("password", "");
    setFormDataProperty("isAutoLogin", false);
  }, []);

  const onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFormDataProperty(name, value);
  };

  const handleChangeAutoLogin = (e) => {
    setFormDataProperty("isAutoLogin", e.target.checked);
  };

  const setFormDataProperty = (name, value) => {
    setFormData((currentFormData) => {
      const nextFormData = {
        ...currentFormData,
        [name]: value,
      };
      return nextFormData;
    });
  };

  const completedCallback = () => {
    //사용자 권한 확인
    apiWrapper(async () => {
      let result = await getUser();
      const { status, data } = result;
      if (status === false) {
        alert(`사용자 권한 확인 실패\n${data}`);
      } else {
        const { isAutoLogin } = formData;
        const { userId, userAuthority } = data;
        if (isAutoLogin) {
          //자동로그인일 때는 로컬스토리지에 저장
          dispatch(setAuthorityInLocal(userAuthority));
        } else {
          //아닐 때는 세션스토리지에 저장
          dispatch(setAuthorityInSession(userAuthority));
        }
      }
    });

    navigate("/");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      apiWrapper(async () => {
        const { userId, password, isAutoLogin } = formData;
        const { status, data } = await login(userId, password);
        if (status === false) {
          setModalShow(true);
        } else {
          const { userId, refresh, access } = data;
          if (isAutoLogin) {
            //자동로그인일 때는 로컬스토리지에 저장
            dispatch(
              setTokenInLocal(access, refresh, userId, completedCallback)
            );
          } else {
            //아닐 때는 세션스토리지에 저장
            dispatch(
              setTokenInSession(access, refresh, userId, completedCallback)
            );
          }
        }
      });
    }

    //유효성 검사 완료 처리
    setValidated(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  return (
    <>
      <Modal show={modalShow} onHide={handleClose} centered>
        <Modal.Body className="text-center">
          <br />
          <div>ID 또는 PW가 일치하지 않습니다.</div>
          <br />
          <Button variant="primary" type="submit" onClick={handleClose}>
            확인
          </Button>
        </Modal.Body>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${BackgroundImage})`,
          backgroundPosition: "center",
          backgroundSize: "100%",
          opacity: "0.8",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "70vh",
            height: "50vh",
            backgroundColor: "rgba(255, 255, 255, 1)",
            borderRadius: "24px",
            boxShadow: "0 3px 24px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                className="text-center fw-bold "
                style={{ marginBottom: "55px", fontSize: "2.8rem" }}
              >
                RTU Manager
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {formData && (
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label
                        column
                        sm={3}
                        className="text-center border rounded"
                        style={{ height: "38px" }}
                      >
                        ID
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="text"
                          name="userId"
                          value={formData.userId}
                          onChange={onChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          ID를 입력하세요.
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label
                        column
                        sm={3}
                        className="text-center border rounded"
                        style={{ height: "38px" }}
                      >
                        PW
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="password"
                          placeholder=""
                          name="password"
                          value={formData.password}
                          onChange={onChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          PW를 입력하세요.
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Check
                      className="mb-5"
                      type="checkbox"
                      label="자동로그인"
                      id="rememberId"
                      value={formData.isAutoLogin}
                      onChange={handleChangeAutoLogin}
                    />
                    <div className="d-grid gap-2">
                      <Button variant="primary" type="submit" size="lg">
                        로그인
                      </Button>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
