import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./Header.scss";

import { Link } from "react-router-dom";
import AuthorityCheckRoute from "utils/AuthorityCheckRoute";
import { UserAuthorityChoice } from "utils/common";
import { REACT_APP_LOGO_IMAGE_NAME } from "Constants";
console.log(REACT_APP_LOGO_IMAGE_NAME);
export default function Header() {
  const [logoImage, setLogoImage] = useState(null);

  useEffect(() => {
    const loadLogoImage = async () => {
      try {
        // Assume you have a dynamic variable LOGO_IMAGE_NAME
        const dynamicImage = await import(
          `../../assets/${REACT_APP_LOGO_IMAGE_NAME}`
        );
        setLogoImage(dynamicImage.default); // Assuming the image is the default export
      } catch (error) {
        console.error("Error loading logo image:", error);
      }
    };

    loadLogoImage();
  }, []);

  return (
    <>
      <Navbar
        bg="primary"
        variant="dark"
        style={{ marginLeft: "0", maxHeight: "80px" }}
      >
        <Container
          style={{ marginLeft: "0", marginRight: "0", maxWidth: "100%" }}
        >
          <Navbar.Brand>
            <AuthorityCheckRoute
              authority={[
                UserAuthorityChoice.ADMIN,
                UserAuthorityChoice.REMS_AND_API,
                UserAuthorityChoice.REMS,
                UserAuthorityChoice.API,
              ]}
            >
              <div className="title">
                <Link
                  to="/"
                  style={{
                    color: "inherit",
                    textDecoration: "inherit",
                    marginLeft: 20,
                  }}
                >
                  RTU Manager
                </Link>
              </div>
            </AuthorityCheckRoute>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse
            className="justify-content-end"
            style={{ marginRight: "20px" }}
          >
            <Navbar.Text>
              {logoImage && <img src={logoImage} width={250} alt="Logo" />}
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
