import { apiWrapper } from "apis/CommonApi";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Button, Container, Table } from "react-bootstrap";
import "./HomePage.scss";
import * as homeApi from "../apis/HomeApi";
import { convertNumberToHex } from "utils/number";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

export default function HomePage() {
  const [dataSource, setDataSource] = useState(null);
  const [errorRtuList, setErrorRtuList] = useState(null);
  const printRef = useRef(null);

  useEffect(() => {
    apiWrapper(async () => {
      let result = await homeApi.getDashboardMonitor();
      const { status, data } = result;
      if (status === true) {
        setDataSource(data);
      }
    });
  }, []);

  const handleRefreshErrorRtuList = (e, deviceDivision) => {
    apiWrapper(async () => {
      let result = await homeApi.getDashboardErrorRtus(deviceDivision);
      const { status, data } = result;
      if (status === true) {
        setErrorRtuList(data);
      }
    });
  };

  const handlePrint = useReactToPrint({
    content: () => {
      return printRef?.current;
    },
    documentTitle: "RTU 등록현황 및 고장 RTU 리스트",
  });

  return (
    <div ref={printRef} className="print-area">
      <Container fluid className="mb-3 d-flex align-items-center">
        <h1 className="title">RTU Server 페이지</h1>
        <span className="ms-auto">
          <label className="ms-2">{`Update : ${
            dataSource && dataSource.updatedTime
          }`}</label>
        </span>
      </Container>

      <div className="rtu-reg">
        <Container fluid className="mb-3 d-flex align-items-center">
          <h2>RTU 등록현황</h2>
          <span className="ms-auto">
            <Button
              style={{ width: "130px" }}
              className="ms-2 print-button"
              variant="primary"
              onClick={handlePrint}
            >
              출력
            </Button>
          </span>
        </Container>
        <div className="rtu-reg-table">
          <div className="table-table">
            <Table bordered hover>
              <thead className="table-header">
                <tr>
                  <th rowSpan={2}>기기 모델</th>
                  <th colSpan={3}>사용</th>
                  <th rowSpan={2}>미사용</th>
                  <th rowSpan={2}>전체 계</th>
                </tr>
                <tr>
                  <th>정상</th>
                  <th>고장</th>
                  <th>사용 계</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {dataSource &&
                  dataSource.rtuStatus &&
                  dataSource.rtuStatus.map((item, index) => (
                    <tr
                      key={index}
                      style={
                        index === dataSource.rtuStatus.length - 1
                          ? { backgroundColor: "#D9D9D9", fontWeight: "bold" }
                          : null
                      }
                    >
                      <td>{item.deviceDivision}</td>
                      <td>{item.normalCount}</td>
                      <td>
                        <Link
                          onClick={(e) =>
                            handleRefreshErrorRtuList(
                              e,
                              item.deviceDivision === "합계"
                                ? "all"
                                : item.deviceDivision
                            )
                          }
                        >
                          {item.errorCount}
                        </Link>
                      </td>
                      <td>{item.useTotalCount}</td>
                      <td>{item.noUseTotalCount}</td>
                      <td>{item.totalCount}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <div className="rtu-error">
        <Container fluid className="mt-3 mb-3 d-flex align-items-center">
          <h2>고장 RTU 리스트</h2>
        </Container>
        <div className="rtu-error-table">
          <div className="table-table">
            <Table bordered hover>
              <thead className="table-header">
                <tr>
                  <th>No</th>
                  <th>기기 모델</th>
                  <th>RTU IMEI</th>
                  <th>사업자 ID</th>
                  <th>서비스 ID</th>
                  <th>최종 Log 수신 일시</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {errorRtuList &&
                  errorRtuList.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.deviceDivision}</td>
                      <td>{item.rtuImei}</td>
                      <td>{convertNumberToHex(item.businessId)}</td>
                      <td>{convertNumberToHex(item.serviceId)}</td>
                      <td>{item.lastLogDate}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
