import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "./PaginatedItems.scss";

export default function PaginatedItems(props) {
  let pageCount;
  if (props.totalItemsCount > 0) {
    pageCount = Math.ceil(props.totalItemsCount / props.itemsPerPage);
  } else {
    pageCount = 1;
  }

  const handlePageClick = (e) => {
    props.onClick(e);

    //페이지 이동 시 스크롤 맨 위로 이동
    let tableRef = document.getElementsByClassName("table-table")[0];
    tableRef?.scrollTo(0, 0);
  };

  return (
    <>
      <ReactPaginate
        className="pagination"
        breakLabel="•••"
        nextLabel={<i className="xi-angle-right"></i>}
        onClick={handlePageClick}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        previousLabel={<i className="xi-angle-left"></i>}
        renderOnZeroPageCount={null}
        forcePage={props.selectedPage}
      />
    </>
  );
}
