import React from "react";
import AppLayout from "./components/common/AppLayout";
import Header from "./components/common/Header";
import Sidebar from "./components/common/Sidebar";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import { ProSidebarProvider } from "react-pro-sidebar";
import RtuManagePage from "./pages/RtuManagePage";
import RtuRequestPage from "./pages/RtuRequestPage";
import RtuReceiveLogPage from "./pages/RtuReceiveLogPage";
import RtuSendLogPage from "./pages/RtuSendLogPage";
import RemsSendLogPage from "./pages/RemsSendLogPage";
import ExternalApiSendLogPage from "./pages/ExternalApiSendLogPage";
import RtuSettingRoutes from "pages/routes/RtuSettingRoutes";
import RemsInterlockAddRoutes from "pages/routes/RemsInterlockAddRoutes";
import ExternalApiRoutes from "pages/routes/ExternalApiRoutes";
import AuthorityCheckRoute from "utils/AuthorityCheckRoute";
import { UserAuthorityChoice } from "utils/common";

export default function Main() {
  return (
    <ProSidebarProvider>
      <AppLayout header={<Header />} sidebar={<Sidebar />}>
        <Routes>
          <Route
            path="*"
            element={
              <AuthorityCheckRoute
                authority={[
                  UserAuthorityChoice.ADMIN,
                  UserAuthorityChoice.REMS_AND_API,
                  UserAuthorityChoice.REMS,
                  UserAuthorityChoice.API,
                ]}
              >
                <HomePage />
              </AuthorityCheckRoute>
            }
          />
          <Route
            path="/rtu-manage/"
            element={
              <AuthorityCheckRoute
                authority={[
                  UserAuthorityChoice.ADMIN,
                  UserAuthorityChoice.REMS_AND_API,
                  UserAuthorityChoice.REMS,
                  UserAuthorityChoice.API,
                ]}
              >
                <RtuManagePage />
              </AuthorityCheckRoute>
            }
          />
          <Route
            path="/rtu-setting/*"
            element={
              <AuthorityCheckRoute
                authority={[
                  UserAuthorityChoice.ADMIN,
                  UserAuthorityChoice.REMS_AND_API,
                  UserAuthorityChoice.REMS,
                  UserAuthorityChoice.API,
                ]}
              >
                <RtuSettingRoutes />
              </AuthorityCheckRoute>
            }
          />
          <Route
            path="/rtu-request/"
            element={
              <AuthorityCheckRoute
                authority={[
                  UserAuthorityChoice.ADMIN,
                  UserAuthorityChoice.REMS_AND_API,
                  UserAuthorityChoice.REMS,
                  UserAuthorityChoice.API,
                ]}
              >
                <RtuRequestPage />
              </AuthorityCheckRoute>
            }
          />
          <Route
            path="/rtu-receive-log/"
            element={
              <AuthorityCheckRoute
                authority={[
                  UserAuthorityChoice.ADMIN,
                  UserAuthorityChoice.REMS_AND_API,
                  UserAuthorityChoice.REMS,
                  UserAuthorityChoice.API,
                ]}
              >
                <RtuReceiveLogPage />
              </AuthorityCheckRoute>
            }
          />
          <Route
            path="/rtu-send-log/"
            element={
              <AuthorityCheckRoute
                authority={[
                  UserAuthorityChoice.ADMIN,
                  UserAuthorityChoice.REMS_AND_API,
                  UserAuthorityChoice.REMS,
                  UserAuthorityChoice.API,
                ]}
              >
                <RtuSendLogPage />
              </AuthorityCheckRoute>
            }
          />
          <Route
            path="/rems-interlock-add/*"
            element={
              <AuthorityCheckRoute
                authority={[
                  UserAuthorityChoice.ADMIN,
                  UserAuthorityChoice.REMS_AND_API,
                  UserAuthorityChoice.REMS,
                ]}
              >
                <RemsInterlockAddRoutes />
              </AuthorityCheckRoute>
            }
          />
          <Route
            path="/rems-send-log/"
            element={
              <AuthorityCheckRoute
                authority={[
                  UserAuthorityChoice.ADMIN,
                  UserAuthorityChoice.REMS_AND_API,
                  UserAuthorityChoice.REMS,
                ]}
              >
                <RemsSendLogPage />
              </AuthorityCheckRoute>
            }
          />
          <Route
            path="/exapi/*"
            element={
              <AuthorityCheckRoute
                authority={[
                  UserAuthorityChoice.ADMIN,
                  UserAuthorityChoice.REMS_AND_API,
                  UserAuthorityChoice.API,
                ]}
              >
                <ExternalApiRoutes />
              </AuthorityCheckRoute>
            }
          />
          <Route
            path="/exapi-send-log/"
            element={
              <AuthorityCheckRoute
                authority={[
                  UserAuthorityChoice.ADMIN,
                  UserAuthorityChoice.REMS_AND_API,
                  UserAuthorityChoice.API,
                ]}
              >
                <ExternalApiSendLogPage />
              </AuthorityCheckRoute>
            }
          />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </AppLayout>
    </ProSidebarProvider>
  );
}
