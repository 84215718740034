import { apiWrapper } from "apis/CommonApi";
import PaginatedItems from "components/common/PaginatedItems";
import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Form,
  InputGroup,
  Col,
  Row,
  Button,
  Table,
} from "react-bootstrap";
import "./RtuManagePage.scss";
import * as rtuManageApi from "../apis/RtuManageApi";
import { convertHexToNumber, convertNumberToHex } from "utils/number";
import { getUseName } from "utils/common";
import Loading from "components/common/Loading";

const StatusTypes = {
  ALL: "0",
  USE: "1",
  NO_USE: "2",
};

const getStatusTypeName = (value) => {
  let name = "";
  const key = Object.keys(StatusTypes)[value];
  switch (key) {
    case "ALL":
      name = "전체";
      break;
    case "USE":
      name = "사용";
      break;
    case "NO_USE":
      name = "미사용";
      break;
    default:
      break;
  }
  return name;
};

const SearchTypes = {
  RTU_IMEI: "0",
  DEVICE_DIVISION: "1",
  FW_VERSION: "2",
  BUS_ID: "3",
  SER_ID: "4",
  API_ID: "5",
  KOR_PAR_ID: "6",
  KOR_API_ID: "7",
};

const getSearchTypeName = (value) => {
  let name = "";
  const key = Object.keys(SearchTypes)[value];
  switch (key) {
    case "RTU_IMEI":
      name = "RTU IMEI";
      break;
    case "DEVICE_DIVISION":
      name = "기기 모델";
      break;
    case "FW_VERSION":
      name = "FW Ver";
      break;
    case "BUS_ID":
      name = "사업자 ID";
      break;
    case "SER_ID":
      name = "서비스 ID";
      break;
    case "API_ID":
      name = "API ID";
      break;
    case "KOR_PAR_ID":
      name = "KOREMS 파싱 ID";
      break;
    case "KOR_API_ID":
      name = "KOREMS API ID";
      break;
    default:
      break;
  }
  return name;
};

export default function RtuManagePage() {
  const itemsPerPage = 100;
  const searchTextRef = useRef(null);
  const [dataSource, setDataSource] = useState([]);
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const [page, setPage] = useState(1);
  const [statusType, setStatusType] = useState(StatusTypes.ALL);
  const [searchType, setSearchType] = useState(SearchTypes.RTU_IMEI);
  const [checkIds, setCheckIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData(1);
  }, []);

  //page: 1베이스
  const getData = (page, url = null) => {
    apiWrapper(async () => {
      setIsLoading(true);
      let result;
      if (url !== null) {
        result = await rtuManageApi.getRtuManagesByUrl(url);
      } else {
        const { searchText } = getSearchParams();
        result = await rtuManageApi.getRtuManages(
          statusType,
          searchType,
          searchText,
          page,
          itemsPerPage
        );
      }
      setIsLoading(false);

      const { status, data } = result;
      if (status === true) {
        setDataSource(data);

        setPrevious(data.previous);
        setNext(data.next);
        setPage(page);
      }
    });
  };

  const getSearchParams = () => {
    let params = { searchText: null };

    if (searchTextRef.current && searchTextRef.current?.value !== "") {
      let searchText = searchTextRef.current?.value;
      switch (searchType) {
        case SearchTypes.BUS_ID:
        case SearchTypes.SER_ID:
          searchText = convertHexToNumber(searchText);
          break;
        default:
          break;
      }
      params = { ...params, searchText: searchText };
    }

    return params;
  };

  const handlePageClick = (e) => {
    if (typeof e.nextSelectedPage !== "undefined") {
      const selectedPage = e.nextSelectedPage + 1;
      let url = null;
      if (e.isPrevious) {
        url = previous;
      } else if (e.isNext) {
        url = next;
      }

      getData(selectedPage, url);
    }
  };

  const handleChangeStatusType = (e) => {
    setStatusType(e.target.value);
  };

  const handleChangeSearchType = (e) => {
    setSearchType(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getData(1);
  };

  const handleDownloadExcel = (e) => {
    apiWrapper(async () => {
      const { searchText } = getSearchParams();
      const result = await rtuManageApi.exportExcelRtuManages(
        statusType,
        searchType,
        searchText
      );

      const { status, data } = result;
      if (status === false) {
        alert(`엑셀 저장에 실패하였습니다.\n${data}`);
      }
    });
  };

  const handleChangeRtuStatus = (e, isUse) => {
    apiWrapper(async () => {
      //status :
      //NO_USE = "0", "미사용"
      //USE = "1", "사용"
      const requestBody = {
        status: isUse ? "1" : "0",
        ids: checkIds,
      };
      const result = await rtuManageApi.updateRtuStatus(requestBody);

      const { status, data } = result;
      if (status === true) {
        alert("상태를 업데이트하였습니다.");
        getData(page);
      } else {
        alert(`상태 업데이트를 실패하였습니다.\n${data}`);
      }
    });
  };

  const handleDelete = (e) => {
    if (checkIds.length < 1) {
      alert("삭제할 항목을 선택하세요.");
    } else if (window.confirm("삭제하시겠습니까?")) {
      apiWrapper(async () => {
        const requestBody = {
          ids: checkIds,
        };
        const result = await rtuManageApi.deleteRtus(requestBody);

        const { status, data } = result;
        if (status === true) {
          alert("삭제되었습니다.");
          getData(page);
        } else {
          alert(`삭제 실패하였습니다.\n${data}`);
        }
      });
    }
  };

  const handleChangeCheckAll = (e) => {
    if (dataSource.results) {
      if (e.target.checked) {
        //전체 체크
        let allIds = [];
        dataSource.results.map((item, index) => {
          allIds.push(item.id);
        });

        setCheckIds(allIds);
      } else {
        //전체 체크 해제
        setCheckIds([]);
      }
    }
  };

  const handleChangeCheck = (e, id) => {
    if (e.target.checked) {
      setCheckIds([...checkIds, id]);
    } else {
      setCheckIds(checkIds.filter((a) => a !== id));
    }
  };

  return (
    <div>
      <Container fluid className="mb-3 d-flex align-items-center">
        <h1>RTU 관리</h1>
      </Container>

      <Container fluid className="mb-3 d-flex align-items-center">
        <Form onSubmit={handleSearch}>
          <Row className="align-items-center">
            <Col xs={"auto"}>
              <InputGroup>
                <InputGroup.Text>RTU 상태</InputGroup.Text>
                <Form.Select
                  value={statusType}
                  onChange={handleChangeStatusType}
                >
                  {Object.values(StatusTypes).map((item, index) => (
                    <option key={index} value={item}>
                      {getStatusTypeName(item)}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
            </Col>
            <Col xs={"auto"}>
              <InputGroup>
                <Form.Select
                  style={{ width: "150px" }}
                  value={searchType}
                  onChange={handleChangeSearchType}
                >
                  {Object.values(SearchTypes).map((item, index) => (
                    <option key={index} value={item}>
                      {getSearchTypeName(item)}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control
                  ref={searchTextRef}
                  style={{ width: "300px" }}
                  placeholder="검색할 문자열을 입력하세요."
                />
                <Button
                  variant="primary"
                  style={{ width: "130px" }}
                  type="submit"
                >
                  검색
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
        <span className="ms-auto">
          <Button
            className="ms-2"
            variant="primary"
            onClick={handleDownloadExcel}
          >
            엑셀 내려받기
          </Button>
          <Button
            className="ms-2"
            variant="primary"
            onClick={(e) => handleChangeRtuStatus(e, true)}
          >
            사용
          </Button>
          <Button
            className="ms-2"
            variant="primary"
            onClick={(e) => handleChangeRtuStatus(e, false)}
          >
            미사용
          </Button>
          <Button className="ms-2" variant="primary" onClick={handleDelete}>
            삭제
          </Button>
        </span>
      </Container>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="rtu-man-table">
          <div className="table-table">
            <Table bordered hover>
              <thead className="table-header">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      style={{ zoom: 1.0 }}
                      className="form-check-input"
                      onChange={handleChangeCheckAll}
                    />
                  </th>
                  <th>RTU IMEI</th>
                  <th>기기 모델</th>
                  <th>FW Ver.</th>
                  <th>사업자 ID</th>
                  <th>서비스 ID</th>
                  <th>등록 일시</th>
                  <th>API ID</th>
                  <th>KOREMS 파싱 ID</th>
                  <th>KOREMS API ID</th>
                  <th>KOREMS PwrPlant ID</th>
                  <th>RTU 상태</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {dataSource.results &&
                  dataSource.results.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          style={{ zoom: 1.0 }}
                          className="form-check-input"
                          onChange={(e) => handleChangeCheck(e, item.id)}
                          checked={checkIds.includes(item.id)}
                        />
                      </td>
                      <td>{item.rtuImei}</td>
                      <td>{item.deviceDivision}</td>
                      <td>{item.fwVersion}</td>
                      <td>{convertNumberToHex(item.businessId)}</td>
                      <td>{convertNumberToHex(item.serviceId)}</td>
                      <td>{item.createdDate}</td>
                      <td>{item.externalApiId}</td>
                      <td>{item.koremsParsingLogicId}</td>
                      <td>{item.koremsExternalApiId}</td>
                      <td>{item.koremsPowerplantId}</td>
                      <td>{getUseName(item.status)}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div style={{ marginTop: 20 }}>
            <PaginatedItems
              totalItemsCount={dataSource.count}
              itemsPerPage={itemsPerPage}
              selectedPage={page - 1}
              onClick={handlePageClick}
            />
          </div>
        </div>
      )}
    </div>
  );
}
